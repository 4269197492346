import axios from 'axios'
import axiosAuth from '../../axios/axios-diampondplugin-api-auth'
import VueJwtDecode from 'vue-jwt-decode'

const state = {
  accessAllowed: true,
  customerConfig: null,
  showPlugin: false,
  errorMsg: 'Loading ...'
}

const mutations = {
  'DENY_ACCESS' (state) {
    state.accessAllowed = false
  },
  'UPDATE_CUSTOMER_CONFIG' (state, value) {
    state.customerConfig = value
  },
  'SHOW_PLUGIN' (state, value) {
    state.showPlugin = value
  },
  'SET_ERROR_MSG' (state, value) {
    state.errorMsg = value
  }
}

const actions = {
  getAccess ({ commit }, body) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      axiosAuth.post('oauth', {
        grant_type: 'client_credentials'
      }).then((data) => {
        axios.create({
          baseURL: process.env.VUE_APP_API_URL,
          headers: {
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + data.data.access_token
          }
        })
          .post('app', body)
          .then(res => {
            const configData = VueJwtDecode.decode(res.data.token)
            commit('UPDATE_CUSTOMER_CONFIG', configData.config)
            resolve()
            if (res.status !== 200) {
              commit('DENY_ACCESS')
              commit('SET_ERROR_MSG', 'There is a configuration Error, please verify the settings in you admin panel.')
              resolve()
            }
          })
          .catch(() => {
            commit('DENY_ACCESS')
            commit('SET_ERROR_MSG', 'There is a configuration Error, please verify the settings in you admin panel.')
            resolve()
          })
      }).catch(() => {
        commit('DENY_ACCESS')
        commit('SET_ERROR_MSG', 'There is a configuration Error, please verify the settings in you admin panel.')
        resolve()
      })
    })
  },
  showPlugin ({ commit }, data) {
    commit('SHOW_PLUGIN', data)
  }
}

const getters = {
  auth (state) {
    return state.accessAllowed
  },
  customerConfig (state) {
    return state.customerConfig
  },
  showPlugin (state) {
    return state.showPlugin
  },
  showErrorMsg (state) {
    return state.errorMsg
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
