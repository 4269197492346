<template>
  <b-col cols="12">
    <b-row>
      <b-col cols="12">
        <h4 class="text-center">{{ $t('shape') }}</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
          <b-form-checkbox-group
            class="text-center shape-container"
            id="shapesCollection"
            v-model="selectedShape"
            @change="search"
            buttons
            button-variant="outline-secondary"
          >
            <b-form-checkbox  class="col" value="Round" v-if="filters.shapes.indexOf('Round') !== -1">
              <svg width="55" x="0px" y="0px" viewBox="-7 0 184.62 184.62" version="1.1" class="shaperound">
                <g id="round" class="shapecolors">
                  <ellipse cx="93" cy="92.4" rx="78.1" ry="78.5"/>
                  <line x1="93" y1="28.1" x2="36" y2="92.6"/>
                  <line x1="93" y1="156.4" x2="36" y2="92.6"/>
                  <line x1="150.3" y1="92.3" x2="93" y2="156.4"/>
                  <line x1="93" y1="28.1" x2="150.3" y2="92.3"/>
                  <line x1="134.8" y1="134.4" x2="114.8" y2="132.1"/>
                  <line x1="51.2" y1="134.4" x2="71.5" y2="132.4"/>
                  <line x1="114.8" y1="132.1" x2="71.5" y2="132.4"/>
                  <line x1="134.8" y1="50.4" x2="114.5" y2="52.3"/>
                  <line x1="51.2" y1="50.8" x2="71.4" y2="52.6"/>
                  <line x1="114.5" y1="52.3" x2="71.4" y2="52.6"/>
                  <line x1="51.2" y1="50.4" x2="52.9" y2="73.5"/>
                  <line x1="51.2" y1="134.4" x2="53" y2="111.7"/>
                  <line x1="52.9" y1="73.5" x2="53" y2="111.7"/>
                  <line x1="134.8" y1="50.4" x2="133.1" y2="73.1"/>
                  <line x1="134.8" y1="134.4" x2="133.2" y2="111.5"/>
                  <line x1="133.1" y1="73.1" x2="133.2" y2="111.5"/>
                  <line x1="164.9" y1="61.7" x2="134.8" y2="50.4"/>
                  <line x1="150.3" y1="92.6" x2="164.9" y2="61.7"/>
                  <line x1="164.9" y1="123.1" x2="150.3" y2="92.6"/>
                  <line x1="134.8" y1="134.4" x2="164.9" y2="123.1"/>
                  <line x1="51.2" y1="50.8" x2="21" y2="62"/>
                  <line x1="36" y1="92.6" x2="21" y2="62"/>
                  <line x1="21.4" y1="123.7" x2="36" y2="92.6"/>
                  <line x1="51.2" y1="134.4" x2="21.4" y2="123.7"/>
                  <line x1="124.7" y1="164.1" x2="134.8" y2="134.4"/>
                  <line x1="93" y1="156.4" x2="124.7" y2="164.1"/>
                  <line x1="61.4" y1="164.1" x2="93" y2="156.4"/>
                  <line x1="51.2" y1="133.8" x2="61.4" y2="164.1"/>
                  <line x1="51.2" y1="50.8" x2="62.2" y2="20.7"/>
                  <line x1="93" y1="28.1" x2="62.2" y2="20.7"/>
                  <line x1="134.8" y1="50.4" x2="123.8" y2="20.7"/>
                  <line x1="93" y1="28.1" x2="123.8" y2="20.7"/>
                  <line x1="93" y1="13.9" x2="93" y2="28.1"/>
                  <line x1="93" y1="170.9" x2="93" y2="156.4"/>
                  <line x1="36" y1="92.6" x2="14.9" y2="92.6"/>
                  <line x1="150.3" y1="92.3" x2="171.1" y2="92.3"/>
                </g>
              </svg>
              <br />
              {{ $t('round') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Princess" v-if="filters.shapes.indexOf('Princess') !== -1">
              <svg width="55" x="0px" y="0px" viewBox="-4 0 184.62 184.62" version="1.1" class="shapeprincess">
                <g id="princess" transform="translate(97.000000, 11.000000)" class="shapecolors">
                  <polygon points="-84.2,159.9 76.7,159.9 76.7,2.9 -84.2,2.9"/>
                  <line x1="-61.3" y1="10.8" x2="53" y2="10.8"/>
                  <line x1="48.4" y1="30.1" x2="53" y2="10.8"/>
                  <line x1="70" y1="21.6" x2="48.4" y2="30.1"/>
                  <line x1="69.6" y1="140" x2="70" y2="21.6"/>
                  <line x1="48.8" y1="132.7" x2="69.6" y2="140"/>
                  <line x1="53.4" y1="152" x2="48.8" y2="132.7"/>
                  <line x1="-60.1" y1="151.8" x2="53.4" y2="152"/>
                  <line x1="-56.3" y1="132.7" x2="-60.1" y2="151.8"/>
                  <line x1="-78" y1="142.4" x2="-56.3" y2="132.7"/>
                  <line x1="-77.4" y1="22.1" x2="-78" y2="142.4"/>
                  <line x1="-56.3" y1="30.1" x2="-77.4" y2="22.1"/>
                  <line x1="-61.3" y1="10.8" x2="-56.3" y2="30.1"/>
                  <line x1="48.4" y1="30.1" x2="-56.3" y2="30.1"/>
                  <line x1="-56.5" y1="133.6" x2="-56.3" y2="30.1"/>
                  <line x1="48.8" y1="132.7" x2="-56.5" y2="133.6"/>
                  <line x1="48.4" y1="30.1" x2="48.8" y2="132.7"/>
                  <line x1="-3.8" y1="30.1" x2="76.7" y2="2.9"/>
                  <line x1="48.8" y1="81.4" x2="76.7" y2="2.9"/>
                  <line x1="76.7" y1="159.9" x2="48.8" y2="81.4"/>
                  <line x1="-3.8" y1="132.7" x2="76.7" y2="159.9"/>
                  <line x1="-84.2" y1="159.9" x2="-3.8" y2="132.7"/>
                  <line x1="-56.3" y1="81.4" x2="-84.2" y2="159.9"/>
                  <line x1="-84.2" y1="2.9" x2="-56.3" y2="81.4"/>
                  <line x1="-3.8" y1="30.1" x2="-84.2" y2="2.9"/>
                </g>
              </svg>
              <br />
              {{ $t('princess') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Heart" v-if="filters.shapes.indexOf('Heart') !== -1">
              <svg width="55" x="0px" y="0px" viewBox="-8 0 184.62 184.62" version="1.1" class="shapeheart">
                <g id="heart" transform="translate(21.000000, 11.000000)" class="shapecolors">
                  <path d="M71.1,159.9c38.8-48.5,66.6-83.2,75-93.4c0.4-0.5,1.6-2,2.8-4.1c6.1-11,4.1-25.8-1-35.8c-7.5-14.8-25.1-25.9-44-23.2c-21.5,3.1-31.6,22-32.5,23.9c-0.8-1.6-1.9-3.7-3.6-5.9C59.6,10.7,43.4,2.2,26.5,5.2c-0.8,0.1-1.6,0.3-2.4,0.5C5.7,10.4-6.9,30.9-7.3,47.4c-0.2,8.3,2.7,19.3,7.9,28.6c1.2,2.1,2.3,3.8,3.1,4.9L71.1,159.9z"/>
                  <line x1="33.3" y1="27.5" x2="71.1" y2="41.2"/>
                  <line x1="111.6" y1="27.5" x2="71.1" y2="41.2"/>
                  <line x1="122.5" y1="5.1" x2="111.6" y2="27.5"/>
                  <line x1="148.9" y1="28.8" x2="111.6" y2="27.5"/>
                  <line x1="127.7" y1="51.2" x2="148.9" y2="28.8"/>
                  <line x1="105.3" y1="62.9" x2="127.7" y2="51.2"/>
                  <line x1="133.2" y1="82.2" x2="127.7" y2="51.2"/>
                  <line x1="106.8" y1="93.6" x2="133.2" y2="82.2"/>
                  <line x1="105.3" y1="62.9" x2="106.8" y2="93.6"/>
                  <line x1="86.3" y1="102.5" x2="106.8" y2="93.6"/>
                  <line x1="107.8" y1="114" x2="106.8" y2="93.6"/>
                  <line x1="86" y1="125.6" x2="107.8" y2="114"/>
                  <line x1="86.3" y1="102.5" x2="86" y2="125.6"/>
                  <line x1="92.5" y1="133.1" x2="86" y2="125.6"/>
                  <line x1="58.8" y1="103.1" x2="86" y2="125.6"/>
                  <line x1="86.3" y1="102.5" x2="58.4" y2="125.6"/>
                  <line x1="36.8" y1="92.9" x2="58.8" y2="103.1"/>
                  <line x1="58.4" y1="125.6" x2="58.8" y2="103.1"/>
                  <line x1="35.3" y1="117.9" x2="58.4" y2="125.6"/>
                  <line x1="36.8" y1="92.9" x2="35.3" y2="117.9"/>
                  <line x1="37.6" y1="63.1" x2="36.8" y2="92.9"/>
                  <line x1="15.8" y1="51.2" x2="37.6" y2="63.1"/>
                  <line x1="6.7" y1="84.3" x2="15.8" y2="51.2"/>
                  <line x1="36.8" y1="92.9" x2="6.7" y2="84.3"/>
                  <line x1="71.1" y1="159.9" x2="58.4" y2="125.6"/>
                  <line x1="86" y1="125.6" x2="71.1" y2="159.9"/>
                  <line x1="86.3" y1="102.5" x2="117" y2="38.5"/>
                  <line x1="71.1" y1="32.5" x2="117" y2="38.5"/>
                  <line x1="25.2" y1="38.5" x2="71.1" y2="32.5"/>
                  <line x1="58.8" y1="103.1" x2="25.2" y2="38.5"/>
                  <line x1="-5.9" y1="58.8" x2="15.8" y2="51.2"/>
                  <line x1="33.3" y1="27.5" x2="15.8" y2="51.2"/>
                  <line x1="-1.7" y1="27.5" x2="15.8" y2="51.2"/>
                  <line x1="33.3" y1="27.5" x2="-1.7" y2="27.5"/>
                  <line x1="22.2" y1="6.2" x2="33.3" y2="27.5"/>
                  <line x1="50.1" y1="8" x2="33.3" y2="27.5"/>
                  <line x1="71.1" y1="32.5" x2="50.1" y2="8"/>
                  <line x1="93.7" y1="6.2" x2="71.1" y2="32.5"/>
                  <line x1="111.6" y1="27.5" x2="93.7" y2="6.2"/>
                  <line x1="117" y1="38.5" x2="111.6" y2="27.5"/>
                  <line x1="71.3" y1="27.3" x2="71.1" y2="41.2"/>
                  <line x1="58.4" y1="125.6" x2="50.1" y2="135.3"/>
                  <line x1="127.7" y1="51.2" x2="150.5" y2="58.7"/>
                  <line x1="117" y1="38.5" x2="127.7" y2="51.2"/>
                </g>
              </svg>
              <br />
              {{ $t('heart') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Cushion" v-if="filters.shapes.indexOf('Cushion') !== -1">
              <svg width="55" x="0px" y="0px" viewBox="-4 0 184.62 184.62" version="1.1" class="shapecushion">
                <g id="cushion" transform="translate(21.000000, 11.000000)" class="shapecolors">
                  <path d="M141,154.2c-4.8,3.6-10.7,5.7-17.1,5.7H21.3c-15.7,0-28.6-12.8-28.6-28.4v-100c0-15.6,12.9-28.4,28.6-28.4h102.6c15.7,0,28.6,12.8,28.6,28.4v100.1C152.5,140.7,148,149,141,154.2"/>
                  <line x1="-7.3" y1="81.4" x2="14.2" y2="124.8"/>
                  <line x1="134.2" y1="37.5" x2="152.6" y2="32.2"/>
                  <line x1="14.2" y1="124.8" x2="35.2" y2="114.9"/>
                  <line x1="132.5" y1="125" x2="152.4" y2="132.9"/>
                  <line x1="115.2" y1="141.8" x2="117.5" y2="159.8"/>
                  <line x1="30" y1="142" x2="25.9" y2="159.8"/>
                  <line x1="26.3" y1="2.9" x2="35.5" y2="42.3"/>
                  <line x1="-7.3" y1="33.7" x2="35.5" y2="42.3"/>
                  <line x1="-1.8" y1="14.7" x2="9.9" y2="37.5"/>
                  <line x1="30" y1="18.1" x2="2.6" y2="9.9"/>
                  <line x1="143.6" y1="152" x2="132.5" y2="125"/>
                  <line x1="110.8" y1="113.8" x2="132.5" y2="125"/>
                  <line x1="30" y1="142" x2="110.8" y2="113.8"/>
                  <line x1="65" y1="159.9" x2="30" y2="142"/>
                  <line x1="139.6" y1="155.5" x2="115.2" y2="141.8"/>
                  <line x1="110.8" y1="113.8" x2="115.2" y2="141.8"/>
                  <line x1="119.9" y1="81.2" x2="110.8" y2="113.8"/>
                  <line x1="110.5" y1="42" x2="119.9" y2="81.2"/>
                  <line x1="30" y1="18.1" x2="110.5" y2="42"/>
                  <line x1="115.2" y1="141.8" x2="35.2" y2="114.9"/>
                  <line x1="78.7" y1="159.9" x2="115.2" y2="141.8"/>
                  <line x1="-7.3" y1="132.3" x2="14.2" y2="124.8"/>
                  <line x1="2.9" y1="153.1" x2="14.2" y2="124.8"/>
                  <line x1="30" y1="142" x2="5.7" y2="155.3"/>
                  <line x1="35.2" y1="114.9" x2="30" y2="142"/>
                  <line x1="25.9" y1="81.2" x2="35.2" y2="114.9"/>
                  <line x1="35.5" y1="42.3" x2="25.9" y2="81.2"/>
                  <line x1="140.5" y1="8.3" x2="116.4" y2="16.2"/>
                  <line x1="35.5" y1="42.3" x2="116.4" y2="16.2"/>
                  <line x1="134.2" y1="37.5" x2="145.5" y2="12.8"/>
                  <line x1="119.9" y1="81.2" x2="134.2" y2="37.5"/>
                  <line x1="152.5" y1="82.3" x2="134.2" y2="37.5"/>
                  <line x1="132.5" y1="125" x2="152.5" y2="82.3"/>
                  <line x1="119.9" y1="81.2" x2="132.5" y2="125"/>
                  <line x1="-7.3" y1="81.4" x2="9.9" y2="37.5"/>
                  <line x1="25.9" y1="81.2" x2="9.9" y2="37.5"/>
                  <line x1="14.2" y1="124.8" x2="25.9" y2="81.2"/>
                  <path d="M110.5,42l9.1-39.1"/>
                  <path d="M65,2.9L30,18.1"/>
                  <path d="M81.1,2.9l35.4,13.3"/>
                  <path d="M134.2,37.5L110.5,42"/>
                </g>
              </svg>
              <br />
              {{ $t('cushion') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Oval" v-if="filters.shapes.indexOf('Oval') !== -1">
              <svg width="55" x="0px" y="0px" viewBox="-12 0 184.62 184.62" version="1.1" class="shapeoval">
                <g id="oval" transform="translate(27.000000, 11.000000)" class="shapecolors">
                  <path d="M118.2,81.4c0,43.3-23.5,78.5-52.5,78.5s-52.5-35.1-52.5-78.5S36.7,2.9,65.7,2.9S118.2,38.1,118.2,81.4z"/>
                  <line x1="92.9" y1="148.5" x2="82.7" y2="135.4"/>
                  <line x1="86.4" y1="109" x2="82.7" y2="135.4"/>
                  <line x1="103.1" y1="99.9" x2="86.4" y2="109"/>
                  <line x1="27.5" y1="99.9" x2="44.1" y2="110.1"/>
                  <line x1="49.8" y1="135.4" x2="44.1" y2="110.1"/>
                  <line x1="37" y1="147.1" x2="49.8" y2="135.4"/>
                  <line x1="116.7" y1="99.8" x2="103.1" y2="99.9"/>
                  <line x1="103.8" y1="135.4" x2="103.1" y2="99.9"/>
                  <line x1="82.7" y1="135.4" x2="103.8" y2="135.4"/>
                  <line x1="14.6" y1="99.8" x2="27.5" y2="99.9"/>
                  <line x1="27.5" y1="135.4" x2="27.5" y2="99.9"/>
                  <line x1="49.8" y1="135.4" x2="27.5" y2="135.4"/>
                  <line x1="25.3" y1="56.1" x2="41.9" y2="52.4"/>
                  <line x1="65.7" y1="37.2" x2="41.9" y2="52.4"/>
                  <line x1="87.8" y1="52.4" x2="65.7" y2="37.2"/>
                  <line x1="106" y1="56.1" x2="87.8" y2="52.4"/>
                  <line x1="90.8" y1="78.5" x2="106" y2="56.1"/>
                  <line x1="118.1" y1="78" x2="106" y2="56.1"/>
                  <line x1="103.1" y1="99.9" x2="118.1" y2="78"/>
                  <line x1="90.8" y1="78.5" x2="103.1" y2="99.9"/>
                  <line x1="25.3" y1="56.1" x2="38.6" y2="78.5"/>
                  <line x1="27.5" y1="99.9" x2="38.6" y2="78.5"/>
                  <line x1="13.2" y1="81.4" x2="27.5" y2="99.9"/>
                  <line x1="25.3" y1="56.1" x2="13.2" y2="81.4"/>
                  <line x1="84.4" y1="24" x2="90.8" y2="78.5"/>
                  <line x1="86.4" y1="109" x2="90.8" y2="78.5"/>
                  <line x1="49.8" y1="135.4" x2="86.4" y2="109"/>
                  <line x1="65.7" y1="159.9" x2="82.7" y2="135.4"/>
                  <line x1="49.8" y1="135.4" x2="65.7" y2="159.9"/>
                  <line x1="82.7" y1="135.4" x2="44.1" y2="110.1"/>
                  <line x1="38.6" y1="78.5" x2="44.1" y2="110.1"/>
                  <line x1="45.8" y1="21.6" x2="38.6" y2="78.5"/>
                  <line x1="93.3" y1="14.8" x2="84.4" y2="24"/>
                  <line x1="106" y1="31.2" x2="84.4" y2="24"/>
                  <line x1="106" y1="56.1" x2="106" y2="31.2"/>
                  <line x1="115.4" y1="56.1" x2="106" y2="56.1"/>
                  <line x1="38" y1="14.8" x2="45.8" y2="21.6"/>
                  <line x1="25.3" y1="31.2" x2="45.8" y2="21.6"/>
                  <line x1="25.3" y1="56.1" x2="25.3" y2="31.2"/>
                  <line x1="16" y1="56.1" x2="25.3" y2="56.1"/>
                  <line x1="84.4" y1="24" x2="65.7" y2="2.9"/>
                  <line x1="45.8" y1="21.6" x2="65.7" y2="2.9"/>
                  <line x1="65.7" y1="37.2" x2="45.8" y2="21.6"/>
                  <line x1="84.4" y1="24" x2="65.7" y2="37.2"/>
                </g>
              </svg>
              <br />
              {{ $t('oval') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Emerald" v-if="filters.shapes.indexOf('Emerald') !== -1">
              <svg width="55" x="0px" y="0px" viewBox="-8 0 184.62 184.62" version="1.1" class="shapeemerald">
                <g id="emerald" transform="translate(26.000000, 11.000000)" class="shapecolors">
                  <polygon points="103.93,159.87 27.69,159.87 8.5,142.01 8.5,22.44 26.85,2.95 106.04,2.95 125.16,22.97 125.16,142.01"/>
                  <line x1="46.3" y1="31.9" x2="87" y2="31.9"/>
                  <line x1="96.8" y1="44.6" x2="87" y2="31.9"/>
                  <line x1="46.3" y1="31.9" x2="36.9" y2="44.2"/>
                  <line x1="36.9" y1="119.6" x2="36.9" y2="44.2"/>
                  <line x1="46.7" y1="130.9" x2="36.9" y2="119.6"/>
                  <line x1="85.9" y1="130.9" x2="46.7" y2="130.9"/>
                  <line x1="96.8" y1="119.6" x2="85.9" y2="130.9"/>
                  <line x1="96.8" y1="44.6" x2="96.8" y2="119.6"/>
                  <line x1="93.4" y1="22.6" x2="39.7" y2="22.6"/>
                  <line x1="106.4" y1="37.6" x2="93.4" y2="22.6"/>
                  <line x1="106.4" y1="126.8" x2="106.4" y2="37.6"/>
                  <line x1="92" y1="140.2" x2="106.4" y2="126.8"/>
                  <line x1="40.3" y1="140.2" x2="92" y2="140.2"/>
                  <line x1="27.2" y1="126.9" x2="40.3" y2="140.2"/>
                  <line x1="27.2" y1="37.2" x2="27.2" y2="126.9"/>
                  <line x1="39.7" y1="22.6" x2="27.2" y2="37.2"/>
                  <line x1="34.9" y1="14.9" x2="98.1" y2="15.1"/>
                  <line x1="113.8" y1="31.6" x2="98.1" y2="15.1"/>
                  <line x1="113.8" y1="133.1" x2="113.8" y2="31.6"/>
                  <line x1="96.4" y1="147.7" x2="113.8" y2="133.1"/>
                  <line x1="35.5" y1="148" x2="96.4" y2="147.7"/>
                  <line x1="19.5" y1="133.3" x2="35.5" y2="148"/>
                  <line x1="19.3" y1="30.8" x2="19.5" y2="133.3"/>
                  <line x1="34.9" y1="14.9" x2="19.3" y2="30.8"/>
                  <path d="M87,31.9l19.1-29"/>
                  <path d="M96.8,44.6L125.2,23"/>
                  <path d="M26.9,2.9l19.4,29"/>
                  <path d="M8.5,22.4l28.4,21.8"/>
                  <path d="M8.5,142l28.4-22.4"/>
                  <path d="M46.7,130.9l-19,29"/>
                  <path d="M85.9,130.9l18.1,29"/>
                  <path d="M96.8,119.6l28.4,22.4"/>
                </g>
              </svg>
              <br />
              {{ $t('emerald') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Asscher" v-if="filters.shapes.indexOf('Asscher') !== -1">
              <svg width="55" x="0px" y="0px" viewBox="-5 0 184.62 184.62" version="1.1" class="shapeasscher">
                <g id="asscher" transform="translate(22.000000, 11.000000)" class="shapecolors">
                  <polygon id="Stroke-1_1_" points="26.1,2.9 -9,37.6 -9,123.4 26.1,159.9 113.6,159.9 151,125.2 151,38.5 115.3,2.9"/>
                  <line x1="37.1" y1="23" x2="103.5" y2="23"/>
                  <line x1="130.1" y1="49.2" x2="103.5" y2="23"/>
                  <line x1="130.1" y1="115.3" x2="130.1" y2="49.2"/>
                  <line x1="103.5" y1="143.6" x2="130.1" y2="115.3"/>
                  <line x1="35.2" y1="143.6" x2="103.5" y2="143.6"/>
                  <line x1="9.5" y1="115.3" x2="35.2" y2="143.6"/>
                  <line x1="9.5" y1="48.2" x2="9.5" y2="115.3"/>
                  <line x1="37.1" y1="23" x2="9.5" y2="48.2"/>
                  <line x1="42.9" y1="33.5" x2="96.9" y2="33.5"/>
                  <line x1="119.4" y1="55.6" x2="96.9" y2="33.5"/>
                  <line x1="119.4" y1="109.8" x2="119.4" y2="55.6"/>
                  <line x1="96.1" y1="131.6" x2="119.4" y2="109.8"/>
                  <line x1="43.5" y1="131.6" x2="96.1" y2="131.6"/>
                  <line x1="20.9" y1="109.2" x2="43.5" y2="131.6"/>
                  <line x1="20.9" y1="55.5" x2="20.9" y2="109.2"/>
                  <line x1="42.9" y1="33.5" x2="20.9" y2="55.5"/>
                  <line x1="48" y1="43" x2="91.8" y2="43"/>
                  <line x1="109.3" y1="61.1" x2="91.8" y2="43"/>
                  <line x1="109.3" y1="105.4" x2="109.3" y2="61.1"/>
                  <line x1="90.9" y1="123.1" x2="109.3" y2="105.4"/>
                  <line x1="48" y1="123.1" x2="90.9" y2="123.1"/>
                  <line x1="30.8" y1="104.5" x2="48" y2="123.1"/>
                  <line x1="30.8" y1="60.7" x2="30.8" y2="104.5"/>
                  <line x1="48" y1="43" x2="30.8" y2="60.7"/>
                  <line x1="91.8" y1="43" x2="115.3" y2="2.9"/>
                  <line x1="109.3" y1="105.4" x2="151" y2="125.2"/>
                  <line x1="90.9" y1="123.1" x2="113.5" y2="159.9"/>
                  <line x1="109.3" y1="61.1" x2="151" y2="38.5"/>
                  <line x1="48" y1="123.1" x2="26.1" y2="159.9"/>
                  <line x1="26.1" y1="2.9" x2="48" y2="43"/>
                  <line x1="30.8" y1="60.7" x2="-9" y2="37.6"/>
                  <line x1="30.8" y1="104.5" x2="-9" y2="123.4"/>
                </g>
              </svg>
              <br />
              {{ $t('asscher') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Radiant" v-if="filters.shapes.indexOf('Radiant') !== -1">
              <svg width="55" x="0px" y="0px" viewBox="-5 0 184.62 184.62" version="1.1" class="shaperadiant">
                <g id="radiant" transform="translate(26.000000, 11.000000)" class="shapecolors">
                  <polygon points="25.8,2.9 8.9,20 8.9,142.6 28.5,159.9 107.3,159.9 125.2,142.6 125.2,22.8 107.8,2.9"/>
                  <line x1="90" y1="130.6" x2="125.2" y2="142.6"/>
                  <line x1="107.3" y1="159.9" x2="90" y2="130.6"/>
                  <line x1="28.5" y1="159.9" x2="43.2" y2="130.6"/>
                  <line x1="8.9" y1="142.6" x2="43.2" y2="130.6"/>
                  <line x1="93.1" y1="30.1" x2="107.8" y2="2.9"/>
                  <line x1="125.2" y1="22.8" x2="93.1" y2="30.1"/>
                  <line x1="25.8" y1="2.9" x2="40.9" y2="32.1"/>
                  <line x1="8.9" y1="20" x2="40.9" y2="32.1"/>
                  <line x1="31.9" y1="14.7" x2="93.1" y2="30.1"/>
                  <line x1="101.4" y1="14.7" x2="31.9" y2="14.7"/>
                  <line x1="109.1" y1="26.4" x2="101.4" y2="14.7"/>
                  <line x1="90" y1="130.6" x2="109.1" y2="26.4"/>
                  <line x1="34.9" y1="147" x2="90" y2="130.6"/>
                  <line x1="31.9" y1="14.7" x2="22.3" y2="25.1"/>
                  <line x1="40.9" y1="32.1" x2="101.4" y2="14.7"/>
                  <line x1="22.3" y1="137.9" x2="40.9" y2="32.1"/>
                  <line x1="22.3" y1="25.1" x2="43.2" y2="130.6"/>
                  <line x1="22.3" y1="137.9" x2="22.3" y2="25.1"/>
                  <line x1="99.7" y1="147" x2="43.2" y2="130.6"/>
                  <line x1="93.1" y1="30.1" x2="109.1" y2="137.1"/>
                  <line x1="109.1" y1="26.4" x2="109.1" y2="137.1"/>
                  <line x1="22.3" y1="137.9" x2="34.9" y2="147"/>
                  <line x1="109.1" y1="137.1" x2="99.7" y2="147"/>
                  <line x1="34.9" y1="147" x2="99.7" y2="147"/>
                </g>
              </svg>
              <br />
              {{ $t('radiant') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Pear" v-if="filters.shapes.indexOf('Pear') !== -1">
              <svg width="55" x="0px" y="0px" viewBox="-8 0 184.62 184.62" version="1.1" class="shapepear">
                <g id="pear" transform="translate(27.000000, 11.000000)" class="shapecolors">
                  <path d="M116.3,81.1c1.7,12.7,4.9,35.9-9.8,55.6c-4.3,5.8-18.6,22.6-41.1,22.6c-23.2,0-37.7-18.1-41.9-24c-13.7-19.5-10.7-41.6-9-54.1C18.8,50.3,65.4,2.9,65.4,2.9S112.1,50.1,116.3,81.1z"/>
                  <line x1="76.5" y1="47.9" x2="93.6" y2="68"/>
                  <line x1="95.2" y1="91" x2="93.6" y2="68"/>
                  <line x1="82.3" y1="122.8" x2="95.2" y2="91"/>
                  <line x1="82.3" y1="140.6" x2="82.3" y2="122.8"/>
                  <line x1="65.7" y1="22.9" x2="65.4" y2="2.9"/>
                  <line x1="54.4" y1="15" x2="65.7" y2="22.9"/>
                  <line x1="37.2" y1="68" x2="55.1" y2="47.9"/>
                  <line x1="18.4" y1="68" x2="37.2" y2="68"/>
                  <line x1="65.7" y1="22.9" x2="37.2" y2="36.3"/>
                  <line x1="37.2" y1="68" x2="37.2" y2="36.3"/>
                  <line x1="37.2" y1="90.3" x2="37.2" y2="68"/>
                  <line x1="47.8" y1="122.7" x2="37.2" y2="90.3"/>
                  <line x1="47.1" y1="140.2" x2="47.8" y2="122.7"/>
                  <line x1="65.7" y1="22.9" x2="93.6" y2="36.3"/>
                  <line x1="93.6" y1="68" x2="93.6" y2="36.3"/>
                  <line x1="112.5" y1="68" x2="93.6" y2="68"/>
                  <line x1="117.8" y1="96.6" x2="93.6" y2="68"/>
                  <line x1="102.6" y1="112.8" x2="117.8" y2="96.6"/>
                  <line x1="13.1" y1="95.4" x2="37.2" y2="68"/>
                  <line x1="28.8" y1="112.8" x2="13.1" y2="95.4"/>
                  <line x1="116.4" y1="114" x2="102.6" y2="112.8"/>
                  <line x1="107.7" y1="135.1" x2="102.6" y2="112.8"/>
                  <line x1="82.3" y1="140.6" x2="107.7" y2="135.1"/>
                  <line x1="92.4" y1="150.4" x2="82.3" y2="140.6"/>
                  <line x1="14.8" y1="115.6" x2="28.8" y2="112.8"/>
                  <line x1="23.6" y1="135.2" x2="28.8" y2="112.8"/>
                  <line x1="47.1" y1="140.2" x2="23.6" y2="135.2"/>
                  <line x1="40" y1="151.3" x2="47.1" y2="140.2"/>
                  <line x1="95.2" y1="91" x2="102.6" y2="112.8"/>
                  <line x1="82.3" y1="122.8" x2="102.6" y2="112.8"/>
                  <line x1="47.1" y1="140.2" x2="82.3" y2="122.8"/>
                  <line x1="65.4" y1="159.3" x2="47.1" y2="140.2"/>
                  <line x1="82.3" y1="140.6" x2="65.4" y2="159.3"/>
                  <line x1="47.8" y1="122.7" x2="82.3" y2="140.6"/>
                  <line x1="28.8" y1="112.8" x2="47.8" y2="122.7"/>
                  <line x1="37.2" y1="90.3" x2="28.8" y2="112.8"/>
                  <line x1="65.7" y1="22.9" x2="95.2" y2="91"/>
                  <line x1="65.7" y1="22.9" x2="76.5" y2="15"/>
                  <line x1="65.7" y1="22.9" x2="37.2" y2="90.3"/>
                </g>
              </svg>
              <br />
              {{ $t('pear') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Marquise" v-if="filters.shapes.indexOf('Marquise') !== -1">
              <svg width="55" x="0px" y="0px" viewBox="-10 0 184.62 184.62" version="1.1" class="shapemarquise">
                <g id="marquise" transform="translate(30.000000, 11.000000)" class="shapecolors">
                  <path d="M103.7,81.3c0,43.4-40.5,78.6-40.5,78.6s-40.5-35.2-40.5-78.6S63.2,2.6,63.2,2.6S103.7,37.8,103.7,81.3z"/>
                  <path d="M76.5,145.1l-6.2-7.7"/>
                  <path d="M88.2,62.9l12.2-4"/>
                  <line x1="63.2" y1="2.6" x2="72.4" y2="28.8"/>
                  <line x1="91.3" y1="38.1" x2="72.4" y2="28.8"/>
                  <line x1="88.2" y1="62.9" x2="91.3" y2="38.1"/>
                  <line x1="82" y1="84.4" x2="88.2" y2="62.9"/>
                  <line x1="72.8" y1="116.4" x2="82" y2="84.4"/>
                  <line x1="70.6" y1="137.8" x2="72.8" y2="116.4"/>
                  <line x1="63.2" y1="159.9" x2="70.6" y2="137.8"/>
                  <line x1="55.6" y1="137.4" x2="63.2" y2="159.9"/>
                  <line x1="85" y1="102" x2="100.3" y2="103.5"/>
                  <line x1="26.5" y1="58" x2="39.3" y2="61.8"/>
                  <line x1="35.1" y1="38.1" x2="39.3" y2="61.8"/>
                  <line x1="54.3" y1="28.8" x2="35.1" y2="38.1"/>
                  <line x1="63.2" y1="2.6" x2="54.3" y2="28.8"/>
                  <line x1="72.4" y1="28.8" x2="22.8" y2="78.3"/>
                  <line x1="70.6" y1="137.8" x2="22.8" y2="78.3"/>
                  <line x1="90.4" y1="126" x2="70.6" y2="137.8"/>
                  <line x1="72.4" y1="28.8" x2="90.4" y2="126"/>
                  <line x1="54.3" y1="28.8" x2="38.3" y2="129.8"/>
                  <line x1="103.7" y1="78.6" x2="54.3" y2="28.8"/>
                  <line x1="55.6" y1="137.4" x2="103.7" y2="78.6"/>
                  <line x1="38.3" y1="129.8" x2="55.6" y2="137.4"/>
                  <line x1="49.3" y1="145.1" x2="55.6" y2="137.4"/>
                  <line x1="39.3" y1="61.8" x2="45.3" y2="85.2"/>
                  <line x1="53.4" y1="116.4" x2="45.3" y2="85.2"/>
                  <line x1="55.6" y1="137.4" x2="53.4" y2="116.4"/>
                  <line x1="42.6" y1="102.8" x2="26.7" y2="105.2"/>
                </g>
              </svg>
              <br />
              {{ $t('marquise') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Trilliant" v-if="filters.shapes.indexOf('Trilliant') !== -1">
              <svg width="64" x="0px" y="0px" viewBox="-5 0 184.62 184.62" version="1.1" class="shapetrilliant">
                <g id="trilliant" class="shapecolors">
                  <path d="M93.28,13.65c34.67,15.43,76.11,100.92,80.34,139.71c-21.99,23.77-143.34,22.94-160.67,0C17.18,114.57,58.61,29.08,93.28,13.65"/>
                  <line x1="153.7" y1="129.2" x2="167.3" y2="126.3"/>
                  <line x1="32.8" y1="129.2" x2="19.3" y2="125.8"/>
                  <line x1="133.9" y1="168" x2="138.9" y2="147.9"/>
                  <line x1="51" y1="167.9" x2="47.2" y2="147.9"/>
                  <line x1="93.3" y1="140.4" x2="153.7" y2="129.2"/>
                  <line x1="143.9" y1="74.5" x2="153.7" y2="129.2"/>
                  <line x1="92.9" y1="55.3" x2="143.9" y2="74.5"/>
                  <line x1="122.9" y1="41.2" x2="92.9" y2="55.3"/>
                  <line x1="92.9" y1="140.4" x2="32.8" y2="129.2"/>
                  <line x1="42.5" y1="74.5" x2="32.8" y2="129.2"/>
                  <line x1="92.9" y1="55.3" x2="42.5" y2="74.5"/>
                  <line x1="63.6" y1="41.2" x2="92.9" y2="55.3"/>
                  <line x1="138.9" y1="147.9" x2="92.9" y2="170.9"/>
                  <line x1="47.2" y1="147.9" x2="92.9" y2="170.9"/>
                  <line x1="138.9" y1="147.9" x2="173.6" y2="153.4"/>
                  <line x1="47.2" y1="147.9" x2="12.9" y2="153.4"/>
                  <line x1="92.9" y1="55.3" x2="13.8" y2="153.2"/>
                  <line x1="173.6" y1="153.4" x2="92.9" y2="55.3"/>
                  <line x1="92.9" y1="140.4" x2="138.9" y2="147.9"/>
                  <line x1="93.3" y1="13.6" x2="138.9" y2="147.9"/>
                  <line x1="47.2" y1="147.9" x2="93.3" y2="140.4"/>
                  <line x1="93.3" y1="13.6" x2="47.2" y2="147.9"/>
                </g>
              </svg>
              <br />
              {{ $t('trilliant') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Triangle" v-if="filters.shapes.indexOf('Triangle') !== -1">
              <svg width="64" x="0px" y="0px" viewBox="0 0 184.62 184.62" version="1.1" class="shapetriangle">
                <g id="triangle" class="shapecolors">
                  <polygon points="92.9,13.7 2.5,171 183.2,171"/>
                  <line x1="92.9" y1="89.2" x2="92.9" y2="13.7"/>
                  <line x1="118" y1="133.1" x2="183.2" y2="171"/>
                  <line x1="67.7" y1="133.1" x2="2.5" y2="171"/>
                  <line x1="92.9" y1="70.2" x2="134.6" y2="142.7"/>
                  <line x1="51.3" y1="142.7" x2="134.6" y2="142.7"/>
                  <line x1="92.9" y1="70.2" x2="51.3" y2="142.7"/>
                  <line x1="92.9" y1="89.2" x2="118" y2="133.1"/>
                  <line x1="67.7" y1="133.1" x2="118" y2="133.1"/>
                  <line x1="92.9" y1="89.2" x2="67.7" y2="133.1"/>
                </g>
              </svg>
              <br />
              {{ $t('triangle') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Baguette" v-if="filters.shapes.indexOf('Baguette') !== -1">
              <svg width="64" x="0px" y="0px" viewBox="0 0 184.62 184.62" version="1.1" class="shapebaguette">
                <g id="baguette" transform="translate(346.000000, 129.000000)" class="shapecolors">
                  <polygon points="-293.2,-115.1 -214.9,-115.1 -214.9,41.9 -293.2,41.9"/>
                  <path d="M-292.7-115.1l11,11.4"/>
                  <path d="M-214.9-115.1l-12,11.4"/>
                  <path d="M-292.7,41.9l11-10.6"/>
                  <path d="M-226.9,31.3l12,10.6"/>
                  <line x1="-226.9" y1="-103.6" x2="-281.7" y2="-103.6"/>
                  <line x1="-226.9" y1="31.3" x2="-281.7" y2="31.3"/>
                  <line x1="-281.7" y1="-103.6" x2="-281.7" y2="31.3"/>
                  <line x1="-226.9" y1="-103.6" x2="-226.9" y2="31.3"/>
                  <line x1="-221.1" y1="-109.1" x2="-221.1" y2="36.8"/>
                  <line x1="-287.4" y1="-109.1" x2="-287.4" y2="36.8"/>
                  <line x1="-287.4" y1="36.8" x2="-221.1" y2="36.8"/>
                  <line x1="-287.4" y1="-109.1" x2="-221.1" y2="-109.1"/>
                </g>
              </svg>
              <br />
              {{ $t('baguette') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Rose" v-if="filters.shapes.indexOf('Rose') !== -1">
              <svg width="64" x="0px" y="0px" viewBox="0 0 184.62 184.62" version="1.1" class="shaperose">
                <g id="rose" class="shapecolors">
                  <path id="path" d="M171.6,92c0,43.7-35.4,79.1-79.1,79.1c-43.7,0-79.1-35.4-79.1-79.1c0-43.7,35.4-79.1,79.1-79.1C136.2,12.9,171.6,48.3,171.6,92z"/>
                  <line x1="40.6" y1="80" x2="142.9" y2="104.4"/>
                  <line x1="78.7" y1="13.9" x2="103.2" y2="40.9"/>
                  <line x1="138" y1="27.3" x2="103.2" y2="40.9"/>
                  <line x1="136.3" y1="64.7" x2="138" y2="27.3"/>
                  <line x1="170.6" y1="79.4" x2="136.3" y2="64.7"/>
                  <line x1="142.9" y1="104.4" x2="170.6" y2="79.4"/>
                  <line x1="156.5" y1="138.4" x2="142.9" y2="104.4"/>
                  <line x1="120.2" y1="136.6" x2="156.5" y2="138.4"/>
                  <line x1="106.4" y1="169.8" x2="120.2" y2="136.6"/>
                  <line x1="80.3" y1="143.3" x2="106.4" y2="169.8"/>
                  <line x1="47.3" y1="156.9" x2="80.3" y2="143.3"/>
                  <line x1="47.5" y1="121.3" x2="47.3" y2="156.9"/>
                  <line x1="14.3" y1="105" x2="47.6" y2="120.3"/>
                  <line x1="40.6" y1="80" x2="14.3" y2="105"/>
                  <line x1="28.1" y1="46" x2="40.6" y2="80"/>
                  <line x1="64.5" y1="46.9" x2="28.1" y2="46"/>
                  <line x1="78.7" y1="13.9" x2="64.5" y2="46.9"/>
                  <line x1="103.2" y1="40.9" x2="64.5" y2="46.9"/>
                  <line x1="40.6" y1="80" x2="64.5" y2="46.9"/>
                  <line x1="47.6" y1="120.3" x2="40.6" y2="80"/>
                  <line x1="80.3" y1="143.3" x2="47.6" y2="120.3"/>
                  <line x1="120.2" y1="136.6" x2="80.3" y2="143.3"/>
                  <line x1="142.9" y1="104.4" x2="120.2" y2="136.6"/>
                  <line x1="136.3" y1="64.7" x2="142.9" y2="104.4"/>
                  <line x1="103.2" y1="40.9" x2="136.3" y2="64.7"/>
                  <line x1="80.3" y1="143.3" x2="103.2" y2="40.9"/>
                  <line x1="64.5" y1="46.9" x2="120.2" y2="136.6"/>
                  <line x1="136.3" y1="64.7" x2="47.6" y2="120.3"/>
                </g>
              </svg>
              <br />
              {{ $t('rose') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Half Moon" v-if="filters.shapes.indexOf('Half Moon') !== -1">
              <svg width="64" x="0px" y="0px" viewBox="0 0 184.62 184.62" version="1.1" class="shapehalf-moon">
                <g id="half-moon" class="shapecolors">
                  <path id="Half-Moon" d="M33.9,142.9l134.1-0.3c2.4-10.3,3.6-18.1,3.6-23.6c0-43.7-35.4-79.1-79.1-79.1S13.4,75.4,13.4,119c0,8.3,1.3,16.4,3.7,23.9H33.9z"/>
                  <line x1="92.5" y1="54.8" x2="92.5" y2="40"/>
                  <line x1="43.6" y1="105" x2="92.5" y2="54.8"/>
                  <line x1="47.2" y1="72.7" x2="43.6" y2="105"/>
                  <line x1="37.6" y1="131.8" x2="43.6" y2="105"/>
                  <line x1="50.7" y1="120.9" x2="43.6" y2="105"/>
                  <line x1="37.6" y1="131.8" x2="50.7" y2="120.9"/>
                  <line x1="134.4" y1="120.9" x2="50.7" y2="120.9"/>
                  <line x1="92.5" y1="54.8" x2="141.6" y2="105.6"/>
                  <line x1="134.4" y1="120.9" x2="141.6" y2="105.6"/>
                  <line x1="92.5" y1="54.8" x2="122.2" y2="46.3"/>
                  <line x1="138.1" y1="72.7" x2="122.2" y2="46.3"/>
                  <line x1="47.2" y1="72.7" x2="137.2" y2="72.7"/>
                  <line x1="147.5" y1="62.2" x2="137.2" y2="72.7"/>
                  <line x1="164.3" y1="87" x2="137.2" y2="72.7"/>
                  <line x1="141.6" y1="105.6" x2="138.1" y2="72.7"/>
                  <line x1="164.3" y1="87" x2="141.6" y2="105.6"/>
                  <line x1="171.6" y1="117" x2="141.6" y2="105.6"/>
                  <line x1="168" y1="142.6" x2="141.6" y2="105.6"/>
                  <line x1="147.5" y1="131.8" x2="142.3" y2="106.6"/>
                  <line x1="134.4" y1="120.9" x2="147.5" y2="131.8"/>
                  <line x1="168" y1="142.6" x2="147.5" y2="131.8"/>
                  <line x1="37.6" y1="131.8" x2="148.5" y2="131.5"/>
                  <line x1="43.6" y1="105" x2="17.1" y2="142.9"/>
                  <line x1="37.6" y1="131.8" x2="17.1" y2="142.9"/>
                  <line x1="43.6" y1="105" x2="13.4" y2="117.9"/>
                  <line x1="20.4" y1="87" x2="43.6" y2="105"/>
                  <line x1="47.2" y1="72.7" x2="20.4" y2="87"/>
                  <line x1="37.6" y1="62.2" x2="47.2" y2="72.7"/>
                  <line x1="63.4" y1="45.8" x2="47.2" y2="72.7"/>
                  <line x1="92.5" y1="54.8" x2="63.4" y2="45.8"/>
                </g>
              </svg>
              <br />
              {{ $t('half_moon') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Hexagon" v-if="filters.shapes.indexOf('Hexagon') !== -1">
              <svg width="64" x="0px" y="0px" viewBox="0 0 184.62 184.62" version="1.1" class="shapehexagon">
                <g id="Hexagon" class="shapecolors">
                  <polygon points="160.3,53.5 160.5,131.6 92.8,170.4 24.8,131.1 24.6,53.1 92.3,14.3"/>
                  <line x1="49.5" y1="66.8" x2="92.8" y2="42.4"/>
                  <line x1="134.8" y1="66.8" x2="92.8" y2="42.4"/>
                  <line x1="134.8" y1="117.4" x2="134.8" y2="66.8"/>
                  <line x1="49.5" y1="66.8" x2="49.5" y2="117.4"/>
                  <line x1="92.2" y1="142.1" x2="49.5" y2="117.4"/>
                  <line x1="134.8" y1="117.4" x2="92.2" y2="142.1"/>
                  <line x1="159.7" y1="53.3" x2="134.8" y2="66.8"/>
                  <line x1="92.8" y1="27.6" x2="147.7" y2="59.9"/>
                  <line x1="147.7" y1="123.9" x2="147.7" y2="59.9"/>
                  <line x1="92.5" y1="155.9" x2="147.7" y2="123.9"/>
                  <line x1="36.7" y1="123.9" x2="92.8" y2="156.2"/>
                  <line x1="36.7" y1="59.9" x2="36.7" y2="123.9"/>
                  <line x1="92.8" y1="27.6" x2="36.7" y2="59.9"/>
                  <line x1="92.8" y1="42.4" x2="92.8" y2="13.9"/>
                  <line x1="49.5" y1="66.8" x2="25.5" y2="52.6"/>
                  <line x1="49.5" y1="117.4" x2="25.5" y2="131.2"/>
                  <line x1="134.8" y1="117.4" x2="160.1" y2="131.2"/>
                  <line x1="92.2" y1="142.1" x2="92.8" y2="170.9"/>
                </g>
              </svg>
              <br />
              {{ $t('hexagon') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Taper Baguette" v-if="filters.shapes.indexOf('Taper Baguette') !== -1">
              <svg width="64" x="0px" y="0px" viewBox="0 0 184.62 184.62" version="1.1" class="shapetaperbaguette">
                <g id="Taper_Baguette" class="shapecolors">
                  <polygon points="113.4,14.6 68.6,14.6 55.2,169.5 127,169.5"/>
                  <line x1="84.3" y1="43.6" x2="96.6" y2="43.3"/>
                  <line x1="102.5" y1="142.2" x2="96.6" y2="43.3"/>
                  <line x1="78.8" y1="141.8" x2="84.3" y2="43.6"/>
                  <line x1="102.5" y1="142.2" x2="78.8" y2="141.8"/>
                  <line x1="55.2" y1="169.5" x2="78.8" y2="141.8"/>
                  <line x1="102.5" y1="142.2" x2="127.2" y2="169.5"/>
                  <line x1="96.6" y1="43.3" x2="113.4" y2="14.6"/>
                  <line x1="84.3" y1="43.6" x2="68.6" y2="14.6"/>
                  <line x1="74.1" y1="24.6" x2="107.4" y2="24.8"/>
                  <line x1="79.8" y1="35.3" x2="101.2" y2="35.4"/>
                  <line x1="119" y1="160.5" x2="63.5" y2="160.1"/>
                  <line x1="107.4" y1="24.8" x2="119" y2="160.5"/>
                  <line x1="79.8" y1="35.3" x2="71.5" y2="150.4"/>
                  <line x1="109.7" y1="150.2" x2="71.5" y2="150.4"/>
                  <line x1="101.2" y1="35.4" x2="109.7" y2="150.2"/>
                  <line x1="74.1" y1="24.6" x2="63.5" y2="160.1"/>
                </g>
              </svg>
              <br />
              {{ $t('taper_baguette') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Shield" v-if="filters.shapes.indexOf('Shield') !== -1">
              <svg width="64" x="0px" y="0px" viewBox="0 0 184.62 184.62" version="1.1" class="shapeshield">
                <g id="Shield" class="shapecolors">
                  <path d="M165.3,44c0,0-23.1,63.5-73.8,125.5c0,0-43.8-51-72.7-125.9l23.1-29h100L165.3,44z"/>
                  <path d="M91.4,146.6c0,0-32.5-38.5-54.4-96.7"/>
                  <path d="M91.3,146.6c0,0,33.2-38.5,55.2-96.7"/>
                  <line x1="146.4" y1="49.9" x2="129.8" y2="29.2"/>
                  <line x1="37" y1="49.9" x2="53.9" y2="29.2"/>
                  <line x1="129.8" y1="29.2" x2="53.9" y2="29.2"/>
                  <line x1="141.9" y1="14.7" x2="129.8" y2="29.2"/>
                  <line x1="165.3" y1="44" x2="146.4" y2="49.9"/>
                  <line x1="91.5" y1="169.5" x2="91.3" y2="146.2"/>
                  <line x1="53.9" y1="29.2" x2="41.9" y2="14.7"/>
                  <line x1="37" y1="49.9" x2="18.8" y2="43.7"/>
                </g>
              </svg>
              <br />
              {{ $t('shield') }}
            </b-form-checkbox>
            <b-form-checkbox  class="col" value="Trapeze" v-if="filters.shapes.indexOf('Trapeze') !== -1">
              <svg width="64" x="0px" y="0px" viewBox="0 0 184.62 184.62" version="1.1" class="shapetrapeze">
                <g id="Trapeze" class="shapecolors">
                  <polygon points="10.7,142.5 174.3,142.5 159.4,44.5 25.5,44.5"/>
                  <line x1="46.8" y1="69.6" x2="137" y2="69.6"/>
                  <line x1="143.3" y1="117.9" x2="40.5" y2="117.9"/>
                  <line x1="46.8" y1="69.6" x2="40.5" y2="117.9"/>
                  <line x1="137" y1="69.6" x2="143.3" y2="117.9"/>
                  <line x1="174.3" y1="142.5" x2="143.3" y2="117.9"/>
                  <line x1="159.4" y1="44.5" x2="137" y2="69.6"/>
                  <line x1="25.5" y1="44.5" x2="46.8" y2="69.6"/>
                  <line x1="40.5" y1="117.9" x2="10.7" y2="142.5"/>
                </g>
              </svg>
              <br />
              {{ $t('trapeze') }}
            </b-form-checkbox>
          </b-form-checkbox-group>

      </b-col>
      <b-form>

      </b-form>
    </b-row>
    <b-row>
      <b-col cols="12" md="6" class="mt-5">
        <div class="hSpace-20"></div>
        <h4 class="text-center">{{ $t('cut_grade') }}</h4>
        <b-form-checkbox-group
          class="text-center cut-container"
          id="cutCollection"
          v-model="selectedCutGrade"
          @change="search"
          buttons
          button-variant="outline-secondary"
        >
          <b-form-checkbox class="col" value="Ideal" v-if="filters.cutGrades.indexOf('Ideal') !== -1">
            {{ $t('ideal') }}
          </b-form-checkbox>
          <b-form-checkbox class="col" value="Excellent" v-if="filters.cutGrades.indexOf('Excellent') !== -1">
            {{ $t('excellent') }}
          </b-form-checkbox>
          <b-form-checkbox class="col" value="Very Good" v-if="filters.cutGrades.indexOf('Very Good') !== -1">
            {{ $t('very_good') }}
          </b-form-checkbox>
          <b-form-checkbox class="col" value="Good" v-if="filters.cutGrades.indexOf('Good') !== -1">
            {{ $t('good') }}
          </b-form-checkbox>
          <b-form-checkbox class="col" value="Fair" v-if="filters.cutGrades.indexOf('Fair') !== -1">
            {{ $t('fair') }}
          </b-form-checkbox>
          <b-form-checkbox class="col" value="Poor" v-if="filters.cutGrades.indexOf('Poor') !== -1">
            {{ $t('poor') }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-col>
      <b-col cols="12" md="6" class="mt-5">
        <h4 class="text-center">{{ $t('color_grade') }}</h4>
        <b-form-checkbox-group
          class="text-center color-container"
          id="colorsCollection"
          v-model="selectedColorGrade"
          @change="search"
          buttons
          button-variant="outline-secondary"
        >
          <b-form-checkbox class="col" value="D" v-if="filters.colors.indexOf('D') !== -1">
            D
          </b-form-checkbox>
          <b-form-checkbox class="col" value="E" v-if="filters.colors.indexOf('E') !== -1">
            E
          </b-form-checkbox>
          <b-form-checkbox class="col" value="F" v-if="filters.colors.indexOf('F') !== -1">
            F
          </b-form-checkbox>
          <b-form-checkbox class="col" value="G" v-if="filters.colors.indexOf('G') !== -1">
            G
          </b-form-checkbox>
          <b-form-checkbox class="col" value="H" v-if="filters.colors.indexOf('H') !== -1">
            H
          </b-form-checkbox>
          <b-form-checkbox class="col" value="I" v-if="filters.colors.indexOf('I') !== -1">
            I
          </b-form-checkbox>
          <b-form-checkbox class="col" value="J" v-if="filters.colors.indexOf('J') !== -1">
            J
          </b-form-checkbox>
          <b-form-checkbox class="col" value="K" v-if="filters.colors.indexOf('K') !== -1">
            K
          </b-form-checkbox>
          <b-form-checkbox class="col" value="L" v-if="filters.colors.indexOf('L') !== -1">
            L
          </b-form-checkbox>
          <b-form-checkbox class="col" value="M-Z" v-if="filters.colors.indexOf('M-Z') !== -1">
            M-Z
          </b-form-checkbox>
          <b-form-checkbox class="col" value="Fancy" v-if="filters.colors.indexOf('Fancy') !== -1">
            {{ $t('fancy') }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-col>
    </b-row>
    <transition name="fancy">
      <b-row v-if="showFancy">
        <b-col cols="12" class="mt-5">
          <h4 class="text-center">{{ $t('fancy_color') }}</h4>
          <b-form-checkbox-group
            class="text-center fancy-color-container"
            id="fancyColorsCollection"
            v-model="selectedFancyColor"
            @change="search"
            buttons
            button-variant="outline-secondary"
          >
            <b-form-checkbox class="col" value="White" v-if="filters.fancyColors.indexOf('White') !== -1">
              {{ $t('white') }}
            </b-form-checkbox>
            <b-form-checkbox class="col" value="Yellow" v-if="filters.fancyColors.indexOf('Yellow') !== -1">
              {{ $t('yellow') }}
            </b-form-checkbox>
            <b-form-checkbox class="col" value="Orange" v-if="filters.fancyColors.indexOf('Orange') !== -1">
              {{ $t('orange') }}
            </b-form-checkbox>
            <b-form-checkbox class="col" value="Cognac" v-if="filters.fancyColors.indexOf('Cognac') !== -1">
              {{ $t('cognac') }}
            </b-form-checkbox>
            <b-form-checkbox class="col" value="Champagne" v-if="filters.fancyColors.indexOf('Champagne') !== -1">
              {{ $t('champagne') }}
            </b-form-checkbox>
            <b-form-checkbox class="col" value="Brown" v-if="filters.fancyColors.indexOf('Brown') !== -1">
              {{ $t('brown') }}
            </b-form-checkbox>
            <b-form-checkbox class="col" value="Green" v-if="filters.fancyColors.indexOf('Green') !== -1">
              {{ $t('green') }}
            </b-form-checkbox>
            <b-form-checkbox class="col" value="Blue" v-if="filters.fancyColors.indexOf('Blue') !== -1">
              {{ $t('blue') }}
            </b-form-checkbox>
            <b-form-checkbox class="col" value="Red" v-if="filters.fancyColors.indexOf('Red') !== -1">
              {{ $t('red') }}
            </b-form-checkbox>
            <b-form-checkbox class="col" value="Pink" v-if="filters.fancyColors.indexOf('Pink') !== -1">
              {{ $t('pink') }}
            </b-form-checkbox>
            <b-form-checkbox class="col" value="Purple" v-if="filters.fancyColors.indexOf('Purple') !== -1">
              {{ $t('purple') }}
            </b-form-checkbox>
            <b-form-checkbox class="col" value="Grey" v-if="filters.fancyColors.indexOf('Grey') !== -1">
              {{ $t('grey') }}
            </b-form-checkbox>
            <b-form-checkbox class="col" value="Black" v-if="filters.fancyColors.indexOf('Black') !== -1">
              {{ $t('black') }}
            </b-form-checkbox>
            <b-form-checkbox class="col" value="Chameleon" v-if="filters.fancyColors.indexOf('Chameleon') !== -1">
              {{ $t('chameleon') }}
            </b-form-checkbox>
            <b-form-checkbox class="col" value="Other" v-if="filters.fancyColors.indexOf('Other') !== -1">
              {{ $t('other') }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
    </transition>
    <b-row>
      <b-col cols="12" md="6" class="mt-5">
        <div class="hSpace-20"></div>
        <h4 class="text-center">{{ $t('clarity_grade') }}</h4>
        <b-form-checkbox-group
          class="text-center clarity-container"
          id="colorsCollection"
          v-model="selectedClarityGrade"
          @change="search"
          buttons
          button-variant="outline-secondary"
        >
          <b-form-checkbox class="col" value="FL-IF" v-if="filters.clarityGrades.indexOf('FL/IF') !== -1">
            FL/IF
          </b-form-checkbox>
          <b-form-checkbox class="col" value="VVS1" v-if="filters.clarityGrades.indexOf('VVS1') !== -1">
            VVS1
          </b-form-checkbox>
          <b-form-checkbox class="col" value="VVS2" v-if="filters.clarityGrades.indexOf('VVS2') !== -1">
            VVS2
          </b-form-checkbox>
          <b-form-checkbox class="col" value="VS1" v-if="filters.clarityGrades.indexOf('VS1') !== -1">
            VS1
          </b-form-checkbox>
          <b-form-checkbox class="col" value="VS2" v-if="filters.clarityGrades.indexOf('VS2') !== -1">
            VS2
          </b-form-checkbox>
          <b-form-checkbox class="col" value="SI1" v-if="filters.clarityGrades.indexOf('SI1') !== -1">
            SI1
          </b-form-checkbox>
          <b-form-checkbox class="col" value="SI2" v-if="filters.clarityGrades.indexOf('SI2') !== -1">
            SI2
          </b-form-checkbox>
          <b-form-checkbox class="col" value="SI3" v-if="filters.clarityGrades.indexOf('SI3') !== -1">
            SI3
          </b-form-checkbox>
          <b-form-checkbox class="col" value="I1" v-if="filters.clarityGrades.indexOf('I1') !== -1">
            I1
          </b-form-checkbox>
          <b-form-checkbox class="col" value="I2" v-if="filters.clarityGrades.indexOf('I2') !== -1">
            I2
          </b-form-checkbox>
          <b-form-checkbox class="col" value="I3" v-if="filters.clarityGrades.indexOf('I3') !== -1">
            I3
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-col>
      <b-col cols="12" md="6" class="mt-5">
        <h4 class="text-center">{{ $t('fluorescence') }}</h4>
        <b-form-checkbox-group
          class="text-center fluorescence-container"
          id="fluorescenceCollection"
          v-model="selectedFluorescence"
          @change="search"
          buttons
          button-variant="outline-secondary"
        >
          <b-form-checkbox class="col" value="None" v-if="filters.fluorescences.indexOf('None') !== -1">
            {{ $t('none') }}
          </b-form-checkbox>
          <b-form-checkbox class="col" value="Very Slight" v-if="filters.fluorescences.indexOf('Very Slight') !== -1">
            {{ $t('very_slight') }}
          </b-form-checkbox>
          <b-form-checkbox class="col" value="Faint" v-if="filters.fluorescences.indexOf('Faint') !== -1">
            {{ $t('faint') }}
          </b-form-checkbox>
          <b-form-checkbox class="col" value="Slight" v-if="filters.fluorescences.indexOf('Slight') !== -1">
            {{ $t('slight') }}
          </b-form-checkbox>
          <b-form-checkbox class="col" value="Medium" v-if="filters.fluorescences.indexOf('Medium') !== -1">
            {{ $t('medium') }}
          </b-form-checkbox>
          <b-form-checkbox class="col" value="Strong" v-if="filters.fluorescences.indexOf('Strong') !== -1">
            {{ $t('strong') }}
          </b-form-checkbox>
          <b-form-checkbox class="col" value="Very Strong" v-if="filters.fluorescences.indexOf('Very Strong') !== -1">
            {{ $t('very_strong') }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6" class="mt-5">
        <div class="hSpace-20"></div>
        <h4 class="text-center">{{ $t('carat') }}</h4>
        <div class="slider-holder">
          <vue-slider
            :min= parseFloat(filters.minCarat)
            :max=parseFloat(filters.maxCarat)
            :interval="0.01"
            class="sliderSpacer"
            @drag-end="search"
            v-model="selectedCaratWeight"
          />
          <div class="fake-slider"></div>
        </div>
        <b-row class="mt-4">
          <b-col cols="6">
            <b-form-input v-model="selectedCaratWeight[0]" @input="debounce" class="text-left caratField"></b-form-input>
          </b-col>
          <b-col cols="6" class="text-right">
            <b-form-input v-model="selectedCaratWeight[1]" @input="debounce" class="text-right caratField pull-right"></b-form-input>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="6" class="mt-5">
        <h4 class="text-center">{{ $t('price') }}</h4>
        <div class="slider-holder">
          <vue-slider
            :min= parseFloat(filters.minPrice)
            :max=parseFloat(filters.maxPrice)
            :interval="10"
            class="sliderSpacer"
            @drag-end="search"
            v-model="selectedPrice"
          />
          <div class="fake-slider"></div>
        </div>
        <b-row class="mt-4">
          <b-col cols="6">
            <b-form-input v-model="selectedPrice[0]" @input="debounce" class="text-left caratField"></b-form-input>
          </b-col>
          <b-col cols="6">
            <b-form-input v-model="selectedPrice[1]" @input="debounce" class="text-right caratField pull-right"></b-form-input>
          </b-col>
        </b-row>

      </b-col>

    </b-row>

    <b-row class="mt-5">
      <b-col cols="12">
        <div class="hSpace-20"></div>
        <h4 class="text-center">{{ $t('certificate') }}</h4>
        <div class="hSpace-20"></div>
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedCertificate"
            :data="certificateSelector"
            name="certificate"
            class="certificate-container"
            buttons
            button-variant="outline-secondary"
            @change="search"
          >
            <b-form-checkbox class="col certificate-display" value="AGS" v-if="filters.certificates.indexOf('AGS') !== -1">
              AGS
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="AGI" v-if="filters.certificates.indexOf('AGI') !== -1">
              AGI
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="GIA" v-if="filters.certificates.indexOf('GIA') !== -1">
              GIA
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="HRD" v-if="filters.certificates.indexOf('HRD') !== -1">
              HRD
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="IGI" v-if="filters.certificates.indexOf('IGI') !== -1">
              IGI
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="EGL ASIA" v-if="filters.certificates.indexOf('EGL ASIA') !== -1">
              EGL ASIA
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="EGL INTL." v-if="filters.certificates.indexOf('EGL INTL.') !== -1">
              EGL INTL.
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="EGL OTHER" v-if="filters.certificates.indexOf('EGL OTHER') !== -1">
              EGL OTHER
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="EGL USA" v-if="filters.certificates.indexOf('EGL USA') !== -1">
              EGL USA
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="AIG" v-if="filters.certificates.indexOf('AIG') !== -1">
              AIG
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="ARGYLE" v-if="filters.certificates.indexOf('ARGYLE') !== -1">
              ARGYLE
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="BGI" v-if="filters.certificates.indexOf('BGI') !== -1">
              BGI
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="CGL" v-if="filters.certificates.indexOf('CGL') !== -1">
              CGL
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="DGI" v-if="filters.certificates.indexOf('DGI') !== -1">
              DGI
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="FM" v-if="filters.certificates.indexOf('FM') !== -1">
              FM
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="GGL" v-if="filters.certificates.indexOf('GGL') !== -1">
              GGL
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="GHI" v-if="filters.certificates.indexOf('GHI') !== -1">
              GHI
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="GS" v-if="filters.certificates.indexOf('GS') !== -1">
              GS
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="GSI" v-if="filters.certificates.indexOf('GSI') !== -1">
              GSI
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="HWL" v-if="filters.certificates.indexOf('HWL') !== -1">
              HWL
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="IGL" v-if="filters.certificates.indexOf('IGL') !== -1">
              IGL
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="IIDGR" v-if="filters.certificates.indexOf('IIDGR') !== -1">
              IIDGR
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="INTDL" v-if="filters.certificates.indexOf('INTDL') !== -1">
              INTDL
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="PGS" v-if="filters.certificates.indexOf('PGS') !== -1">
              PGS
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="WGI" v-if="filters.certificates.indexOf('WGI') !== -1">
              WGI
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="OTHER" v-if="filters.certificates.indexOf('OTHER') !== -1">
              OTHER
            </b-form-checkbox>
            <b-form-checkbox class="col certificate-display" value="NO CERT" v-if="filters.certificates.indexOf('NO CERT') !== -1">
              NO CERT
            </b-form-checkbox>

          </b-form-checkbox-group>
        </b-form-group>

      </b-col>
    </b-row>

    <b-row class="mt-5" v-if="pluginSettings.multipleDiamondTypes">
      <b-col cols="12">
        <div class="hSpace-20"></div>
        <h4 class="text-center">{{ $t('Diamond types') }}</h4>
        <div class="hSpace-20"></div>
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedDiamondTypes"
            :data="diamondTypesSelector"
            name="diamondTypes"
            class="diamond-types-container"
            buttons
            button-variant="outline-secondary"
            @change="search"
          >
            <b-form-checkbox class="col" value="lab" v-if="filters.diamondTypes.indexOf('lab') !== -1">
              {{ $t('Lab Grown') }}
            </b-form-checkbox>
            <b-form-checkbox class="col" value="natural" v-if="filters.diamondTypes.indexOf('natural') !== -1">
              {{ $t('Natural') }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>

      </b-col>
    </b-row>

   <div class="hSpace-20"></div>
  </b-col>
</template>

<script>
import VueSlider from 'vue-slider-component'
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'diamond-filters',
  components: {
    VueSlider
  },
  computed: {
    ...mapGetters([
      'filters',
      'initFilters',
      'pluginSettings'
    ]),
    filters: {
      get: function () {
        return this.$store.getters.filters
      },
      set: function () {
      }
    },
    certificateSelector: {
      get: function () {
        return this.$store.getters.certificateSelector
      },
      set: function () {
      }
    },
    diamondTypesSelector: {
      get: function () {
        return this.$store.getters.diamondTypesSelector
      },
      set: function () {
      }
    },
    diamonds: {
      get: function () {
        return this.$store.getters.diamonds
      },
      set: function () {
      }
    },
    selectedCutGrade: {
      get: function () {
        return this.$store.getters.selectedCutGrade
      },
      set: function (value) {
        this.$store.commit('UPDATE_CUT_GRADE', value)
      }
    },
    selectedShape: {
      get: function () {
        return this.$store.getters.selectedShape
      },
      set: function (value) {
        this.$store.commit('UPDATE_SHAPES', value)
      }
    },
    selectedColorGrade: {
      get: function () {
        return this.$store.getters.selectedColorGrade
      },
      set: function (value) {
        if (value.indexOf('Fancy') !== -1 && value.length > 1 && value.indexOf('Fancy') === (value.length - 1)) {
          value = ['Fancy']
          this.$store.commit('UPDATE_SHOW_FANCY', true)
        }

        if (value.indexOf('Fancy') === 0 && value.length > 1) {
          this.selectedFancyColor = []
          value.splice(0, 1)
          this.$store.commit('UPDATE_SHOW_FANCY', false)
        }

        if (value.indexOf('Fancy') !== -1 && value.length === 1) {
          this.$store.commit('UPDATE_SHOW_FANCY', true)
        }

        if (value.indexOf('Fancy') === -1 && value.length === 0) {
          this.selectedFancyColor = []
          this.$store.commit('UPDATE_SHOW_FANCY', false)
        }
        this.$store.commit('UPDATE_COLORS', value)
      }
    },
    selectedFancyColor: {
      get: function () {
        return this.$store.getters.selectedFancyColor
      },
      set: function (value) {
        this.$store.commit('UPDATE_FANCY_COLORS', value)
      }
    },
    selectedDiamondTypes: {
      get: function () {
        return this.$store.getters.selectedDiamondTypes
      },
      set: function (value) {
        this.$store.commit('UPDATE_DIAMOND_TYPES', value)
      }
    },
    selectedClarityGrade: {
      get: function () {
        return this.$store.getters.selectedClarityGrade
      },
      set: function (value) {
        this.$store.commit('UPDATE_CLARITY', value)
      }
    },
    selectedCaratWeight: {
      get: function () {
        return this.$store.getters.selectedCaratWeight
      },
      set: function (value) {
        this.$store.commit('UPDATE_CARAT', value)
      }
    },
    selectedPrice: {
      get: function () {
        return this.$store.getters.selectedPrice
      },
      set: function (value) {
        this.$store.commit('UPDATE_PRICE', value)
      }
    },
    selectedPolish: {
      get: function () {
        return this.$store.getters.selectedPolish
      },
      set: function (value) {
        this.$store.commit('UPDATE_POLISH', value)
      }
    },
    selectedCertificate: {
      get: function () {
        return this.$store.getters.selectedCertificate
      },
      set: function (value) {
        this.$store.commit('UPDATE_CERTIFICATES', value)
      }
    },
    selectedFluorescence: {
      get: function () {
        return this.$store.getters.selectedFluorescence
      },
      set: function (value) {
        this.$store.commit('UPDATE_FLUORESCENCE', value)
      }
    },
    showFancy: {
      get: function () {
        return this.$store.getters.showFancy
      },
      set: function (value) {
        this.$store.commit('UPDATE_SHOW_FANCY', value)
      }
    }
  },
  data () {
    return {
      value: [30, 70],
      type: 'Range',
      cutGradesSelector: ['Ideal', 'Excellent', 'Very Good', 'Good', 'Fair', 'Poor'],
      colorGradeSelector: ['D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      clarityGradeSelector: ['FL', 'IF', 'VVS1', 'VVS2', 'VS1', 'VS2', 'SI1', 'SI2', 'SI3', 'I1', 'I2', 'I3'],
      caratScope: { min: 0, max: 0 },
      priceScope: { min: 0, max: 0 }
    }
  },
  created () {
    this.search('instantiate')
  },
  methods: {
    ...mapActions([
      'searchDiamonds'
    ]),
    debounce: function () {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        this.search(false)
      }, 500)
    },
    search: _.debounce(function (init) {
      this.debounceSearch(init)
    }, 1000),
    debounceSearch (init) {
      const body = {
        shapes: (init === 'instantiate' || this.selectedShape.length === 0) ? this.initFilters.shapes : this.selectedShape,
        cutGrade: (init === 'instantiate' || this.selectedCutGrade.length === 0) ? this.initFilters.cutGrades : this.selectedCutGrade,
        colorGrade: (init === 'instantiate' || this.selectedColorGrade.length === 0) ? this.initFilters.colors : this.selectedColorGrade,
        clarityGrade: (init === 'instantiate' || this.selectedClarityGrade.length === 0) ? this.initFilters.clarityGrades : this.selectedClarityGrade,
        price: (init === 'instantiate') ? this.initFilters.price : this.selectedPrice,
        carat: (init === 'instantiate') ? this.initFilters.carat : this.selectedCaratWeight,
        certificates: (init === 'instantiate' || this.selectedCertificate.length === 0) ? this.initFilters.certificates : this.selectedCertificate,
        fluorescence: (init === 'instantiate' || this.selectedFluorescence.length === 0) ? this.initFilters.fluorescence : this.selectedFluorescence,
        fancyColors: (init === 'instantiate' || this.selectedFancyColor.length === 0) ? this.initFilters.fancyColors : this.selectedFancyColor,
        diamondTypes: (init === 'instantiate' || this.selectedDiamondTypes.length === 0) ? this.initFilters.diamondTypes : this.selectedDiamondTypes
      }

      this.searchDiamonds(body)
    }
  }
}
</script>

<style scoped>
  .caratField, .caratField:focus {
    width:80px;
    border-radius:0px;
    margin-top:5px;
    border:1px solid var(--unselected-background-color);
  }
  .pull-right {
    float:right;
  }
  .shape-container, .certificate-container, .color-container, .clarity-container, .cut-container, .fluorescence-container, .fancy-color-container, .diamond-types-container {
    flex-wrap: wrap;
    width:100%;
  }
  .certificate-container label, .color-container label, .clarity-container label, .cut-container label, .fluorescence-container label, .fancy-color-container label, .diamond-types-container label {
    border-radius:0px;
    border-top:none;
    width:12.5%;
    text-transform:uppercase;
  }

  .cut-container label {
    width:25%;
  }

  .cut-container label, .color-container label, .clarity-container label, .certificate-container label, .fluorescence-container label, .fancy-color-container label, .diamond-types-container label {
    border:1px solid var(--unselected-background-color) !important;
    background:var(--unselected-background-color) !important;
    margin:0.5px !important;
    font-size:11px;
  }

  .cut-container label, .cut-container label:hover, .cut-container label.active,
  .color-container label, .color-container label:hover, .color-container label.active,
  .clarity-container label, .clarity-container label:hover, .clarity-container label.active,
  .certificate-container label, .certificate-container label:hover, .certificate-container label.active,
  .fluorescence-container label, .fluorescence-container label:hover, .fluorescence-container label.active,
  .diamond-types-container label, .diamond-types-container label:hover, .diamond-types-container label.active,
  .fancy-color-container label, .fancy-color-container label:hover, .fancy-color-container label.active{
    color:var(--selected-accent-color) !important;
  }

  .shape-container label, .shape-container label:focus {
    border:1px solid var(--unselected-background-color) !important;
    background:var(--unselected-background-color) !important;
    margin:0.5px !important;
    cursor:pointer;
    color:var(--selected-accent-color) !important;
  }

  .shape-container label.active, .cut-container label.active, .color-container label.active, .clarity-container label.active, .certificate-container label.active, .fluorescence-container label.active, .fancy-color-container label.active, .diamond-types-container label.active {
    color:var(--selected-accent-color) !important;
  }

  .shape-container label:focus, .shape-container label:active,
  .cut-container label:focus, .cut-container label:active,
  .color-container label:focus, .color-container label:active,
  .clarity-container label:focus, .clarity-container label:active,
  .certificate-container label:focus, .certificate-container label:active,
  .fluorescence-container label:focus, .fluorescence-container label:active,
  .diamond-types-container label:focus, .diamond-types-container label:active,
  .fancy-color-container label:focus, .fancy-color-container label:active{
    background-color:var(--unselected-background-color) !important;
    border:1px solid var(--unselected-background-color) !important;
    outline:none !important;
    box-shadow: none !important;
  }

  .cut-container label.active, .cut-container label:hover,
  .shape-container label.active, .shape-container label:hover,
  .color-container label.active, .color-container label:hover,
  .clarity-container label.active, .clarity-container label:hover,
  .certificate-container label.active, .certificate-container label:hover,
  .fluorescence-container label.active, .fluorescence-container label:hover,
  .diamond-types-container label.active, .diamond-types-container label:hover,
  .fancy-color-container label.active, .fancy-color-container label:hover{
    border:1px solid var(--selected-background-color) !important;
    background-color:var(--selected-background-color) !important;
    color:var(--unselected-background-color) !important;
  }

  .shape-container label:first-child, .certificate-container label:first-child, .color-container label:first-child, .clarity-container label:first-child, .cut-container label:first-child, .fluorescence-container label:first-child, .fancy-color-container label:first-child, .diamond-types-container label:first-child {
    margin-left:-1px;
  }

  .slider-holder {
    width:100%;
    padding:0px 42px 0px 8px;
    position:relative;
    margin-top:-7px;
  }

  @media (hover: none) and (pointer: coarse) {
    .cut-container label:hover,
    .color-container label:hover,
    .clarity-container label:hover,
    .certificate-container label:hover,
    .fluorescence-container label:hover,
    .shape-container label:hover,
    .diamond-types-container label:hover,
    .fancy-color-container label:hover{
      border:1px solid var(--unselected-background-color) !important;
      background:var(--unselected-background-color) !important;
      color:var(--selected-accent-color) !important;
    }

    .cut-container label.active:hover,
    .color-container label.active:hover,
    .diamond-types-container label.active:hover,
    .clarity-container label.active:hover,
    .certificate-container label.active:hover,
    .fluorescence-container label.active:hover,
    .shape-container label.active:hover,
    .fancy-color-container label.active:hover{
      border:1px solid var(--selected-background-color) !important;
      background:var(--selected-background-color) !important;
      color:var(--unselected-background-color) !important;
    }
  }

  /*responsive design*/
  @media (min-width: 320px) {
    .shape-container label {
      width:49%;
      font-size:12px;
    }

    .cut-container label {
      width:49%;
    }

    .color-container label {
      width:24%;
    }

    .diamond-types-container label {
      width:49%;
    }

    .clarity-container label {
      width:24%;
    }

    .certificate-container label {
      width:49%;
    }

    .fluorescence-container label {
      width:33%;
    }

    .fancy-color-container label {
      width:33%;
    }
  }

  @media (max-width: 768px) {
    .cut-container label, .color-container label, .clarity-container label, .certificate-container label, .fluorescence-container label, .fancy-color-container label, .diamond-types-container label {
      height:50px;
      font-size:14px;
      line-height:2.8em;
    }
  }

  @media (min-width: 576px) {
    .shape-container label {
      width:33%;
    }

    .cut-container label {
      width:33%;
    }

    .color-container label {
      width:19%;
    }

    .clarity-container label {
      width:16%;
    }

    .certificate-container label {
      width:24%;
    }

    .fluorescence-container label {
      width:24%;
    }

    .fancy-color-container label {
      width:24%;
    }

    .diamond-types-container label {
      width:49%;
    }
  }

  @media (min-width: 768px) {
    .shape-container label {
      width:24%;
    }

    .cut-container label {
      width:49%;
    }

    .color-container label {
      width:24%;
    }

    .clarity-container label {
      width:24%;
    }

    .certificate-container label {
      width:16%;
    }

    .fluorescence-container label {
      width:33%;
    }
    .fancy-color-container label {
      width:12%;
    }
    .diamond-types-container label {
      width:49%;
    }
  }

  @media (min-width: 992px) {
    .shape-container label {
      border-radius:0px;
      width:14%;
    }

    .cut-container label {
      width:33%;
    }

    .color-container label {
      width:16%;
    }

    .clarity-container label {
      width:16%;
    }

    .fluorescence-container label {
      width:24%;
    }

    .diamond-types-container label {
      width:49%;
    }
  }

  @media (min-width: 1200px) {
    .certificate-container label {
      width:14%;
    }
  }
</style>
