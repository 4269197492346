<template>
  <div class="home">
    <b-container fluid class="p-0">
      <div class="hSpace-10"></div>
      <b-row>
        <app-filters></app-filters>
      </b-row>
      <div class="hSpace-10"></div>
      <b-row>
        <app-results></app-results>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import Filters from '../components/diamonds/Filters'
import Results from '../components/diamonds/Results'
export default {
  name: 'search-view',
  components: {
    appFilters: Filters,
    appResults: Results
  },
  data () {
    return {
      viewPortWidth: 800
    }
  }
}
</script>
