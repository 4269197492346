import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_DB_API_URL,
  auth: {
    username: process.env.VUE_APP_DB_LOGIN,
    password: process.env.VUE_APP_DB_PASS
  }
})

export default instance
