import axiosAuth from '../../axios/axios-api-auth'
import axios from 'axios'

const state = {
  filters: {
    shapes: [],
    colors: [],
    cutGrades: [],
    clarityGrades: [],
    fancyColors: [],
    fluorescences: [],
    certificates: [],
    minCarat: 0,
    maxCarat: 0,
    minPrice: 0,
    maxPrice: 0,
    diamondTypes: []
  },
  apiToken: null,
  requestPhone: null,
  requestEmail: null,
  initFilters: {},
  priceSettings: {},
  certificates: [],
  certificateSelector: [],
  diamondTypesSelector: [],
  cutSelector: [],
  colorSelector: [],
  fluorescenceSelector: [],
  diamonds: [],
  totalRecords: 0,
  currentPage: 1,
  loadMore: true,
  pageBusy: false,
  isBusy: false,
  isLoading: false,
  showFancy: false,
  searchParams: {},
  sortBy: {
    field: 'tp',
    type: 'asc'
  },
  rawSort: {
    field: 'tp',
    type: 'asc'
  },
  page: 1,
  selectedCutGrade: [],
  selectedShape: [],
  selectedColorGrade: [],
  selectedFancyColor: [],
  selectedClarityGrade: [],
  selectedFluorescence: [],
  selectedPrice: [0, 0],
  selectedCaratWeight: [0, 0],
  selectedCertificate: [],
  selectedDiamondTypes: [],
  diamondDetail: {},
  diamondsRequest: [],
  pluginSettings: {},
  currencyCodes: {
    CAD: '&#36;',
    HKD: '&#36;',
    ISK: '&#107;&#114;',
    PHP: '&#8369;',
    DKK: '&#107;&#114;',
    HUF: '&#70;&#116;',
    CZK: '&#75;&#269;',
    GBP: '&#163;',
    RON: '&#108;&#101;&#105;',
    SEK: '&#107;&#114;',
    IDR: '&#82;&#112;',
    INR: '&#8377;',
    BRL: '&#82;&#36;',
    RUB: '&#66;&#114;',
    HRK: '&#107;&#110;',
    JPY: '&#165;',
    THB: '&#3647;',
    CHF: '&#67;&#72;&#70;',
    EUR: '&#8364;',
    MYR: '&#82;&#77;',
    BGN: '&#1083;&#1074;',
    TRY: '&#;',
    CNY: '&#165;',
    NOK: '&#107;&#114;',
    NZD: '&#36;',
    ZAR: '&#82;',
    USD: '&#36;',
    MXN: '&#36;',
    SGD: '&#36;',
    AUD: '&#36;',
    ILS: '&#8362;',
    KRW: '&#8361;',
    PLN: '&#122;&#322;'
  }
}

const mutations = {
  'GET_CERTIFICATES' (state, certificates) {
    state.certificates = certificates

    if (certificates.length > 0) {
      state.certificateSelector = []
      for (let i = 0; i < certificates.length; i++) {
        state.certificateSelector.push({
          text: certificates[i]
        })
      }
    }
  },
  'UPDATE_API_TOKEN' (state, value) {
    state.apiToken = value
  },
  'SEARCH_DIAMONDS' (state, diamonds) {
    (diamonds.concat === true) ? state.diamonds = state.diamonds.concat(diamonds.diamonds.diamonds) : state.diamonds = diamonds.diamonds.diamonds
    state.totalRecords = diamonds.diamonds.totalResults
    state.isBusy = false
    state.pageBusy = false

    setTimeout(() => {
      state.loadMore = (state.diamonds.length < state.totalRecords)
    }, 3000)
  },
  'SET_SEARCH_PARAMS' (state, params) {
    state.searchParams = params
  },
  'UPDATE_CUT_GRADE' (state, cutGrade) {
    state.selectedCutGrade = cutGrade
  },
  'UPDATE_SHAPES' (state, shapes) {
    state.selectedShape = shapes
  },
  'UPDATE_COLORS' (state, colors) {
    if (colors.indexOf('M-Z') !== -1 && colors.indexOf('M') === -1) {
      const highColors = ['M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      colors = colors.concat(highColors)
    }

    if (colors.indexOf('M-Z') === -1 && colors.indexOf('M') !== -1) {
      const toRemove = ['M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      colors = colors.filter((el) => !toRemove.includes(el))
    }

    state.selectedColorGrade = colors
  },
  'UPDATE_CLARITY' (state, clarity) {
    if (clarity.indexOf('FL-IF') !== -1 && clarity.indexOf('FL') === -1) {
      const highClarity = ['FL', 'IF']
      clarity = clarity.concat(highClarity)
    }

    if (clarity.indexOf('FL-IF') === -1 && clarity.indexOf('FL') !== -1) {
      const toRemove = ['FL', 'IF']
      clarity = clarity.filter((el) => !toRemove.includes(el))
    }
    state.selectedClarityGrade = clarity
  },
  'UPDATE_FANCY_COLORS' (state, fancyColors) {
    state.selectedFancyColor = fancyColors
  },
  'UPDATE_DIAMOND_TYPES' (state, diamondTypes) {
    state.selectedDiamondTypes = diamondTypes
  },
  'UPDATE_PRICE' (state, price) {
    state.selectedPrice = price
  },
  'UPDATE_CARAT' (state, carat) {
    state.selectedCaratWeight = carat
  },
  'UPDATE_CERTIFICATES' (state, certificates) {
    state.selectedCertificate = certificates
  },
  'UPDATE_FLUORESCENCE' (state, fluorescence) {
    state.selectedFluorescence = fluorescence
  },
  'UPDATE_CURRENT_PAGE' (state, data) {
    state.currentPage = data
  },
  'UPDATE_SHOW_FANCY' (state, data) {
    state.showFancy = data
  },
  'UPDATE_CONTACT_DETAILS' (state, data) {
    state.requestPhone = data.phone
    state.requestEmail = data.email
  },
  'UPDATE_FILTERS' (state, data) {
    state.selectedPrice = [data.minPrice, data.maxPrice]
    state.selectedCaratWeight = [data.minCarat, data.maxCarat]
    state.filters = data
  },
  'UPDATE_PRICE_SETTINGS' (state, data) {
    state.priceSettings = data
  },
  'UPDATE_PLUGIN_SETTINGS' (state, data) {
    state.pluginSettings = data
  },
  'UPDATE_INIT_FILTERS' (state, data) {
    state.initFilters = data
  }
}

const actions = {
  async setPage ({ commit, dispatch, getters }, body) {
    if (state.isLoadingPage) {
      return
    }

    state.isLoadingPage = true

    const customerConfig = await getters.customerConfig
    state.pageBusy = true
    state.currentPage = state.currentPage + body
    state.searchParams.page = state.currentPage
    state.searchParams.id = customerConfig.uuid

    try {
      await dispatch('getToken')

      const response = await axios.create({
        baseURL: process.env.VUE_APP_DB_API_URL,
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + state.apiToken.access_token
        }
      }).post('api/diamonds', state.searchParams)

      const output = response.data.output
      const hits = output.hits.hits

      const diamondsResult = hits.map(hit => {
        const { _source, fields: { finalPrice } } = hit

        if (_source.video !== undefined) {
          _source.ap = _source.video
          delete _source.video
        }

        return {
          ..._source,
          _showDetails: false,
          tp: finalPrice[0]
        }
      })

      const payload = {
        diamonds: {
          totalResults: output.hits.total.value,
          diamonds: diamondsResult
        },
        concat: true
      }

      commit('SEARCH_DIAMONDS', payload)
    } catch (error) {
      return false
      // console.error('Error fetching diamonds:', error)
    } finally {
      state.isLoadingPage = false
    }
  },
  getDiamond ({ commit, dispatch }, body) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await dispatch('getToken')

      await axios.create({
        baseURL: process.env.VUE_APP_DB_API_URL,
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + state.apiToken.access_token
        }
      })
        .get('api/diamonds/' + body.diamondId, body)
        .then(data => {
          commit('UPDATE_DIAMOND_DETAIL', data.data)
          resolve()
        })
        .catch(() => {
          resolve()
        })

      resolve()
    })
  },
  async searchDiamonds ({ commit, dispatch, getters }, body) {
    const customerConfig = await getters.customerConfig
    const diamondTypes = await getters.pluginSettings.diamondTypes

    if (diamondTypes && diamondTypes.length === 1) {
      body.diamondTypes = diamondTypes
    }

    state.diamonds = []
    state.isBusy = true
    state.currentPage = 1

    const colorGrade = (body.colorGrade.length === 1 && body.colorGrade.indexOf('Fancy') !== -1) ? [] : body.colorGrade
    const postVars = {
      limit: 100,
      page: state.currentPage,
      price: {
        min: parseFloat(body.price[0]),
        max: parseFloat(body.price[1])
      },
      carat: {
        min: parseFloat(body.carat[0]),
        max: parseFloat(body.carat[1])
      },
      shapes: body.shapes,
      cutGrade: body.cutGrade,
      colorGrade,
      clarityGrade: body.clarityGrade,
      gradingLab: body.certificates,
      fluorescence: body.fluorescence,
      fancyColors: body.fancyColors,
      sortBy: state.sortBy,
      currencyRate: state.priceSettings.rate,
      margins: state.priceSettings.margins,
      id: customerConfig.uuid,
      diamondTypes: body.diamondTypes
    }

    commit('SET_SEARCH_PARAMS', postVars)

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await dispatch('getToken')

      await axios.create({
        baseURL: process.env.VUE_APP_DB_API_URL,
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + state.apiToken.access_token
        }
      })
        .post('api/diamonds', postVars)
        .then(response => {
          const output = response.data.output
          const hits = output.hits.hits

          const diamondsResult = hits.map(hit => {
            const { _source, fields: { finalPrice } } = hit

            if (_source.video !== undefined) {
              _source.ap = _source.video
              delete _source.video
            }

            return {
              ..._source,
              _showDetails: false,
              tp: finalPrice[0]
            }
          })

          const payload = {
            diamonds: {
              totalResults: output.hits.total.value,
              diamonds: diamondsResult
            },
            concat: false
          }

          commit('SEARCH_DIAMONDS', payload)
          resolve()
        })
        .catch(() => {
          resolve()
        })

      resolve()
    })
  },
  getToken ({ commit, dispatch, state }, body) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const currentDate = new Date(Date.now())
      let dateCheck = false

      if (state.apiToken !== null) {
        const tokenDate = new Date(state.apiToken.expiryDate)
        dateCheck = tokenDate > currentDate
      }

      if (state.apiToken === null || !dateCheck) {
        await axiosAuth.post('oauth', {
          grant_type: 'client_credentials'
        }).then((data) => {
          const d = new Date(Date.now())
          d.setSeconds(d.getSeconds() + data.data.expires_in)
          data.data.expiryDate = d
          commit('UPDATE_API_TOKEN', data.data)
          resolve()
        }).catch((error) => {
          resolve(error)
        })
      }

      resolve()
    })
  },
  setFilters ({ commit }, body) {
    commit('UPDATE_FILTERS', body)

    const initFilters = {
      shapes: body.shapes,
      certificates: body.certificates,
      clarityGrades: body.clarityGrades,
      colors: body.colors,
      cutGrades: body.cutGrades,
      fluorescence: body.fluorescences,
      carat: [body.minCarat, body.maxCarat],
      price: [body.minPrice, body.maxPrice],
      diamondTypes: body.diamondTypes
    }
    commit('UPDATE_INIT_FILTERS', initFilters)
  },
  setContactDetails ({ commit }, body) {
    commit('UPDATE_CONTACT_DETAILS', body)
  },
  setPriceSettings ({ commit }, body) {
    commit('UPDATE_PRICE_SETTINGS', body)
  },
  setPluginSettings ({ commit }, body) {
    commit('UPDATE_PLUGIN_SETTINGS', body)
  },
  async setSort ({ commit, dispatch, getters }, body) {
    const customerConfig = await getters.customerConfig
    state.loadMore = false
    state.isBusy = true
    state.diamonds = []
    let sortBy = {}

    state.rawSort = {
      field: body.sortBy,
      type: body.sortDesc
    }

    switch (body.sortBy) {
      case 'tp':
        sortBy = {
          field: 'tp',
          type: (body.sortDesc === true) ? 'desc' : 'asc'
        }
        break
      case 'ct':
        sortBy = {
          field: 'ct',
          type: (body.sortDesc === true) ? 'desc' : 'asc'
        }
        break
      case 'col':
        sortBy = {
          field: 'col',
          type: (body.sortDesc === true) ? 'desc' : 'asc'
        }
        break
      case 'cut':
        sortBy = {
          field: 'cut',
          type: (body.sortDesc === true) ? 'desc' : 'asc'
        }
        break
      case 'cal':
        sortBy = {
          field: 'col',
          type: (body.sortDesc === true) ? 'desc' : 'asc'
        }
        break
      case 'cl':
        sortBy = {
          field: 'cl',
          type: (body.sortDesc === true) ? 'desc' : 'asc'
        }
        break
      case 'mk':
        sortBy = {
          field: 'mk',
          type: (body.sortDesc === true) ? 'desc' : 'asc'
        }
        break
      case 'lab':
        sortBy = {
          field: 'lab',
          type: (body.sortDesc === true) ? 'desc' : 'asc'
        }
        break
      case 'ga':
        sortBy = {
          field: 'ga',
          type: (body.sortDesc === true) ? 'desc' : 'asc'
        }
        break
      default:
        sortBy = {
          field: 'tp',
          type: (body.sortDesc === true) ? 'desc' : 'asc'
        }
        break
    }

    state.sortBy = sortBy
    state.searchParams.sortBy = sortBy
    state.currentPage = 1
    state.searchParams.page = 1
    state.searchParams.id = customerConfig.uuid

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch('getToken')

        const response = await axios.create({
          baseURL: process.env.VUE_APP_DB_API_URL,
          headers: {
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + state.apiToken.access_token
          }
        }).post('api/diamonds', state.searchParams)

        const output = response.data.output
        const hits = output.hits.hits

        const diamonds = hits.map(hit => {
          const { _source, fields: { finalPrice } } = hit
          _source.ap = _source.video
          delete _source.video

          return {
            ..._source,
            _showDetails: false,
            tp: finalPrice[0]
          }
        })

        const payload = {
          diamonds: {
            totalResults: output.hits.total.value,
            diamonds
          },
          concat: false
        }

        commit('SEARCH_DIAMONDS', payload)
        resolve()
      } catch (error) {
        resolve('Error fetching diamonds:', error)
      }
    })
  }
}

const getters = {
  certificates (state) {
    return state.certificates
  },
  certificateSelector (state) {
    return state.certificateSelector
  },
  diamondTypesSelector (state) {
    return state.diamondTypesSelector
  },
  fluorescenceSelector (state) {
    return state.fluorescenceSelector
  },
  cutSelector (state) {
    return state.cutSelector
  },
  diamonds (state) {
    return state.diamonds
  },
  loadMore (state) {
    return state.loadMore
  },
  isBusy (state) {
    return state.isBusy
  },
  searchParams (state) {
    return state.searchParams
  },
  totalRecords (state) {
    return state.totalRecords
  },
  pageBusy (state) {
    return state.pageBusy
  },
  page (state) {
    return state.page
  },
  contactDetails (state) {
    return {
      email: state.requestEmail,
      phone: state.requestPhone
    }
  },
  selectedCutGrade (state) {
    return state.selectedCutGrade
  },
  selectedFluorescence (state) {
    return state.selectedFluorescence
  },
  selectedDiamondTypes (state) {
    return state.selectedDiamondTypes
  },
  selectedShape (state) {
    return state.selectedShape
  },
  selectedColorGrade (state) {
    return state.selectedColorGrade
  },
  selectedClarityGrade (state) {
    return state.selectedClarityGrade
  },
  selectedFancyColor (state) {
    return state.selectedFancyColor
  },
  selectedPrice (state) {
    return state.selectedPrice
  },
  selectedCaratWeight (state) {
    return state.selectedCaratWeight
  },
  selectedCertificate (state) {
    return state.selectedCertificate
  },
  sortBy (state) {
    return state.sortBy
  },
  rawSort (state) {
    return state.rawSort
  },
  currentPage (state) {
    return state.currentPage
  },
  showFancy (state) {
    return state.showFancy
  },
  filters (state) {
    return state.filters
  },
  priceSettings (state) {
    return state.priceSettings
  },
  currencyCodes (state) {
    return state.currencyCodes
  },
  initFilters (state) {
    return state.initFilters
  },
  pluginSettings (state) {
    return state.pluginSettings
  },
  getCustomerConfig (state, getters, rootState, rootGetters) {
    return rootGetters.customerConfig
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
