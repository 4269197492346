import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import diamonds from './modules/diamonds'
import pluginConnect from './modules/pluginConnect'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    diamonds,
    pluginConnect
  }
})
