// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-54.use[1]!../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-54.use[2]!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-54.use[3]!../node_modules/bootstrap/dist/css/bootstrap.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-54.use[1]!../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-54.use[2]!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-54.use[3]!../node_modules/bootstrap-vue/dist/bootstrap-vue.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-54.use[1]!../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-54.use[2]!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-54.use[3]!../node_modules/vue-slider-component/dist-css/vue-slider-component.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-54.use[1]!../node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-54.use[2]!../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-54.use[3]!./assets/css/theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--body-bg-color:red;--font-family:\"Roboto\";--unselected-background-color:#f5f5f5;--selected-background-color:#dedede;--selected-accent-color:#6c757d;--title-color:#6c757d;--font-color:#6c757d;--button-color:#a4a5a7;--row-even-background-color:#fff;--row-odd-background-color:#f5f5f5;--row-hover-background-color:#dedede;--shape-fill-color:#fff;--selected-shape-fill-color:#fff;--diamond-detail-table-lines:#dee2e6}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
