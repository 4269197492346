<template>
  <div>
    <transition name="plugin">
      <div class="appZone plugin" :class="{'plugin-enter-active': viewPlugin}">
        <b-container fluid v-if="viewPlugin">
          <b-row>
            <b-col cols="12" class="p-0">
              <app-unauthorized v-if="!accessAllowed"></app-unauthorized>
              <router-view v-if="accessAllowed"></router-view>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </transition>

      <div>
        <b-container v-show="viewLoading">
          <b-row>
            <b-col cols="12" class="text-center">
              <b-img :src="require('@/assets/img/round-loading.svg')"></b-img>
            </b-col>
          </b-row>
        </b-container>
      </div>

  </div>

</template>

<script>

import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Unauthorized from './components/error/Unauthorized'
import router from './router'
import store from './store/store'
import WebFont from 'webfontloader'
import Vuelidate from 'vuelidate'
import VueObserveVisibility from 'vue-observe-visibility'
import axios from 'axios'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueObserveVisibility)

Vue.filter('formatNumber', function (number, decimals, decPoint, thousandsSep) {
  let n = number; const c = isNaN(decimals = Math.abs(decimals)) ? 2 : decimals
  const d = decPoint === undefined ? ',' : decPoint
  const t = thousandsSep === undefined ? '.' : thousandsSep; const s = n < 0 ? '-' : ''
  // eslint-disable-next-line no-use-before-define
  const i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + ''; let j = (j = i.length) > 3 ? j % 3 : 0

  return s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '')
})

Vue.filter('calculatePrice', function (price, rate, carat, margins) {
  if (margins.length > 0) {
    for (let i = 0; i < margins.length; i++) {
      if (parseFloat(carat) >= parseFloat(margins[i].min_carat) && parseFloat(carat) <= parseFloat(margins[i].max_carat)) {
        return (price * rate) * (margins[i].margin / 100 + 1)
      }
    }

    return price * rate
  }
  return price * rate
})

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {}
})

export default {
  i18n,
  router,
  store,
  components: {
    appUnauthorized: Unauthorized
  },
  props: {
    token: {
      type: String,
      required: true,
      default: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjdXN0b21lcklkIjo1LCJzdWJzY3JpcHRpb25JZCI6Mn0.Qko3WwoKEr7F0dLLYBkskT88XF3QdIkLnBki22G4ySs'
    },
    lang: {
      type: String,
      required: false
    },
    diamondTypes: {
      type: String,
      required: false
    },
    bgColor: {
      type: String,
      required: false
    },
    fontFamily: {
      type: String,
      required: false
    },
    fontColor: {
      type: String,
      required: false
    },
    unselectedColor: {
      type: String,
      required: false
    },
    selectedColor: {
      type: String,
      required: false
    },
    selectedAccent: {
      type: String,
      required: false
    },
    titleColor: {
      type: String,
      required: false
    },
    buttonColor: {
      type: String,
      required: false
    },
    rowEven: {
      type: String,
      required: false
    },
    rowOdd: {
      type: String,
      required: false
    },
    rowHover: {
      type: String,
      required: false
    },
    shapeFill: {
      type: String,
      required: false
    },
    selectedShape: {
      type: String,
      required: false
    },
    tableLines: {
      type: String,
      required: false
    },
    shapes: {
      type: String,
      required: false
    },
    colors: {
      type: String,
      required: false
    },
    cutGrades: {
      type: String,
      required: false
    },
    clarityGrades: {
      type: String,
      required: false
    },
    fancyColors: {
      type: String,
      required: false
    },
    fluorescences: {
      type: String,
      required: false
    },
    certificates: {
      type: String,
      required: false
    },
    minCarat: {
      type: String,
      required: false
    },
    maxCarat: {
      type: String,
      required: false
    },
    minPrice: {
      type: String,
      required: false
    },
    maxPrice: {
      type: String,
      required: false
    },
    currency: {
      type: String,
      required: false
    },
    currencyRate: {
      type: Number,
      required: false
    },
    margins: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      accessAllowed: 'auth',
      configData: 'customerConfig',
      languageMessages: 'languageMessages',
      pluginIsVisible: 'showPlugin',
      errorMsg: 'showErrorMsg',
      pluginSettings: 'pluginSettings'
    })
  },
  async created () {
    const langSettings = {
      lang: (this.lang !== undefined ? this.lang : 'en')
    }
    await this.getLanguageMessages(langSettings)
    i18n.setLocaleMessage(langSettings.lang, JSON.parse(JSON.stringify(this.languageMessages)))
    i18n.locale = langSettings.lang

    const body = {
      url: window.location.origin,
      token: this.token
    }

    this.getAccess(body).then(() => {
      if (this.shapes !== undefined) {
        const filters = {
          shapes: this.shapes.split(';'),
          colors: this.colors.split(';'),
          cutGrades: this.cutGrades.split(';'),
          clarityGrades: this.clarityGrades.split(';'),
          fancyColors: this.fancyColors.split(';'),
          fluorescences: this.fluorescences.split(';'),
          certificates: this.certificates.split(';'),
          minCarat: this.minCarat,
          maxCarat: this.maxCarat,
          minPrice: this.minPrice,
          maxPrice: this.maxPrice,
          diamondTypes: this.diamondTypes.split(';')
        }

        const priceSettings = {
          currency: this.currency,
          rate: this.currencyRate,
          margins: ((this.margins !== '') ? JSON.parse(this.margins) : {})
        }

        this.setPriceSettings(priceSettings)
        this.setFilters(filters)

        const pluginSettings = {
          id: this.configData.id,
          shoppingCart: this.configData.shopping_cart_settings_id,
          multipleDiamondTypes: this.configData.diamond_types.split(';').length > 1,
          diamondTypes: this.configData.diamond_types.split(';')
        }

        this.setPluginSettings(pluginSettings)
      } else {
        const filters = {
          shapes: this.configData.shapes.split(';'),
          colors: this.configData.colors.split(';'),
          cutGrades: this.configData.cut_grades.split(';'),
          clarityGrades: this.configData.clarity_grades.split(';'),
          fancyColors: this.configData.fancy_colors.split(';'),
          fluorescences: this.configData.fluorescences.split(';'),
          certificates: this.configData.certificates.split(';'),
          diamondTypes: this.configData.diamond_types.split(';'),
          minCarat: this.configData.min_carat,
          maxCarat: this.configData.max_carat,
          minPrice: this.configData.min_price,
          maxPrice: this.configData.max_price
        }

        const priceSettings = {
          currency: this.configData.currency,
          rate: this.configData.currency_rate,
          margins: this.configData.margins
        }

        this.setPriceSettings(priceSettings)
        this.setFilters(filters)

        const contactDetails = {
          phone: this.configData.request_phone,
          email: this.configData.request_email
        }
        this.setContactDetails(contactDetails)

        const pluginSettings = {
          id: this.configData.id,
          shoppingCart: this.configData.shopping_cart_settings_id,
          multipleDiamondTypes: this.configData.diamond_types.split(';').length > 1,
          diamondTypes: this.configData.diamond_types.split(';')
        }

        this.setPluginSettings(pluginSettings)
      }

      this.setUserInformation(this.configData.id)

      this.setStyles()
      this.getWebFonts()
      this.viewLoading = false
      this.viewPlugin = true
    }).catch((error) => {
      this.handleError(error)
    })
  },
  data () {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      viewPlugin: false,
      viewLoading: true
    }
  },
  methods: {
    ...mapActions([
      'getAccess',
      'showPlugin',
      'setFilters',
      'setPriceSettings',
      'setContactDetails',
      'setPluginSettings',
      'postLog',
      'getLanguageMessages'
    ]),
    handleError (error) {
      // eslint-disable-next-line
      console.error(error)
    },
    getWebFonts () {
      const systemFonts = ['Arial', 'Arial Black', 'Arial Narrow', 'Times New Roman', 'Courier New', 'Verdana', 'Georgia', 'Palatino', 'Garamond', 'Bookman', 'Comic Sans MS', 'Impact']
      const textFont = (this.fontFamily !== undefined) ? this.fontFamily.split('+').join(' ') : this.configData.font_family.split('+').join(' ')

      if (systemFonts.indexOf(textFont) === -1) {
        WebFont.load({
          google: {
            families: [textFont]
          }
        })
      }
    },
    setUserInformation (pluginId) {
      if (localStorage.userInformation === undefined) {
        axios.get('https://ipinfo.io/json').then((data) => {
          localStorage.userInformation = JSON.stringify(data.data)
          this.postLog({
            pluginId,
            action: 'action.initplugin'
          })
        })
      }
    },
    setStyles () {
      const root = document.documentElement
      root.style.setProperty('--body-bg-color', (this.bgColor !== undefined) ? this.bgColor : this.configData.body_bg_color)
      root.style.setProperty('--font-family', (this.fontFamily !== undefined) ? this.fontFamily.split('+').join(' ') : this.configData.font_family)
      root.style.setProperty('--unselected-background-color', (this.unselectedColor !== undefined) ? this.unselectedColor : this.configData.unselected_background_color)
      root.style.setProperty('--selected-background-color', (this.selectedColor !== undefined) ? this.selectedColor : this.configData.selected_background_color)
      root.style.setProperty('--selected-accent-color', (this.selectedAccent !== undefined) ? this.selectedAccent : this.configData.selected_accent_color)
      root.style.setProperty('--title-color', (this.titleColor !== undefined) ? this.titleColor : this.configData.title_color)
      root.style.setProperty('--font-color', (this.fontColor !== undefined) ? this.fontColor : this.configData.font_color)
      root.style.setProperty('--button-color', (this.buttonColor !== undefined) ? this.buttonColor : this.configData.button_color)
      root.style.setProperty('--row-even-background-color', (this.rowEven !== undefined) ? this.rowEven : this.configData.row_even_background_color)
      root.style.setProperty('--row-odd-background-color', (this.rowOdd !== undefined) ? this.rowOdd : this.configData.row_odd_background_color)
      root.style.setProperty('--row-hover-background-color', (this.rowHover !== undefined) ? this.rowHover : this.configData.row_hover_background_color)
      root.style.setProperty('--shape-fill-color', (this.shapeFill !== undefined) ? this.shapeFill : this.configData.shape_fill_color)
      root.style.setProperty('--diamond-detail-table-lines', (this.tableLines !== undefined) ? this.tableLines : this.configData.diamond_detail_table_lines)
      root.style.setProperty('--selected-shape-fill-color', (this.selectedShape !== undefined) ? this.selectedShape : this.configData.selected_shape_fill_color)
    }
  }
}
</script>

<style>
  :root {
    --body-bg-color: #ff0000;
    --font-family: 'Roboto';
    --unselected-background-color: #f5f5f5;
    --selected-background-color: #dedede;
    --selected-accent-color:#6c757d;
    --title-color:#6c757d;
    --font-color:#6c757d;
    --button-color:#a4a5a7;
    --row-even-background-color:#ffffff;
    --row-odd-background-color:#f5f5f5;
    --row-hover-background-color:#dedede;
    --shape-fill-color:#ffffff;
    --selected-shape-fill-color:#ffffff;
    --diamond-detail-table-lines:#dee2e6;
  }

  @import '../node_modules/bootstrap/dist/css/bootstrap.css';
  @import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css';
  @import "../node_modules/vue-slider-component/dist-css/vue-slider-component.css";
  @import "assets/css/theme.css";
</style>
