<template>
  <div class="detail-holder">
    <b-row class="diamond-title">
      <b-col cols="7" md="8">
        <h2 class="text-left">{{ diamond.item.ct | formatNumber(2, '.', '') }} Ct. {{ $t(diamond.item.cut.toLowerCase().replace(' ', '_')) }} {{ (pluginSettings.multipleDiamondTypes ? $t(diamond.item.ga) : '') + ' ' + $t('diamond') }}</h2>
      </b-col>
      <b-col cols="5" md="4" class="text-right">
        <h2>{{ diamond.item.tp | calculatePrice(priceSettings.rate, diamond.item.ct, priceSettings.margins) | formatNumber(0, ',', '.') }} <span v-html="currencyCodes[priceSettings.currency]"></span></h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="12" md="4" class="text-center">
        <div class="diamond-image-holder" v-if="imageStatus === 'loaded'">
          <b-img :src="diamond.item.ip" alt="Diamond Image" fluid></b-img>
        </div>
        <div class="diamond-image-holder" v-if="imageStatus === 'error'">
          <svg width="100%" x="0px" y="0px" viewBox="-7 0 184.62 184.62" version="1.1" class="shaperound" v-if="diamond.item.cut === 'Round'">
            <g id="round" class="shapecolors">
              <ellipse cx="93" cy="92.4" rx="78.1" ry="78.5"/>
              <line x1="93" y1="28.1" x2="36" y2="92.6"/>
              <line x1="93" y1="156.4" x2="36" y2="92.6"/>
              <line x1="150.3" y1="92.3" x2="93" y2="156.4"/>
              <line x1="93" y1="28.1" x2="150.3" y2="92.3"/>
              <line x1="134.8" y1="134.4" x2="114.8" y2="132.1"/>
              <line x1="51.2" y1="134.4" x2="71.5" y2="132.4"/>
              <line x1="114.8" y1="132.1" x2="71.5" y2="132.4"/>
              <line x1="134.8" y1="50.4" x2="114.5" y2="52.3"/>
              <line x1="51.2" y1="50.8" x2="71.4" y2="52.6"/>
              <line x1="114.5" y1="52.3" x2="71.4" y2="52.6"/>
              <line x1="51.2" y1="50.4" x2="52.9" y2="73.5"/>
              <line x1="51.2" y1="134.4" x2="53" y2="111.7"/>
              <line x1="52.9" y1="73.5" x2="53" y2="111.7"/>
              <line x1="134.8" y1="50.4" x2="133.1" y2="73.1"/>
              <line x1="134.8" y1="134.4" x2="133.2" y2="111.5"/>
              <line x1="133.1" y1="73.1" x2="133.2" y2="111.5"/>
              <line x1="164.9" y1="61.7" x2="134.8" y2="50.4"/>
              <line x1="150.3" y1="92.6" x2="164.9" y2="61.7"/>
              <line x1="164.9" y1="123.1" x2="150.3" y2="92.6"/>
              <line x1="134.8" y1="134.4" x2="164.9" y2="123.1"/>
              <line x1="51.2" y1="50.8" x2="21" y2="62"/>
              <line x1="36" y1="92.6" x2="21" y2="62"/>
              <line x1="21.4" y1="123.7" x2="36" y2="92.6"/>
              <line x1="51.2" y1="134.4" x2="21.4" y2="123.7"/>
              <line x1="124.7" y1="164.1" x2="134.8" y2="134.4"/>
              <line x1="93" y1="156.4" x2="124.7" y2="164.1"/>
              <line x1="61.4" y1="164.1" x2="93" y2="156.4"/>
              <line x1="51.2" y1="133.8" x2="61.4" y2="164.1"/>
              <line x1="51.2" y1="50.8" x2="62.2" y2="20.7"/>
              <line x1="93" y1="28.1" x2="62.2" y2="20.7"/>
              <line x1="134.8" y1="50.4" x2="123.8" y2="20.7"/>
              <line x1="93" y1="28.1" x2="123.8" y2="20.7"/>
              <line x1="93" y1="13.9" x2="93" y2="28.1"/>
              <line x1="93" y1="170.9" x2="93" y2="156.4"/>
              <line x1="36" y1="92.6" x2="14.9" y2="92.6"/>
              <line x1="150.3" y1="92.3" x2="171.1" y2="92.3"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="-4 0 184.62 184.62" version="1.1" class="shapeprincess" v-if="diamond.item.cut === 'Princess'">
            <g id="princess" transform="translate(97.000000, 11.000000)" class="shapecolors">
              <polygon points="-84.2,159.9 76.7,159.9 76.7,2.9 -84.2,2.9"/>
              <line x1="-61.3" y1="10.8" x2="53" y2="10.8"/>
              <line x1="48.4" y1="30.1" x2="53" y2="10.8"/>
              <line x1="70" y1="21.6" x2="48.4" y2="30.1"/>
              <line x1="69.6" y1="140" x2="70" y2="21.6"/>
              <line x1="48.8" y1="132.7" x2="69.6" y2="140"/>
              <line x1="53.4" y1="152" x2="48.8" y2="132.7"/>
              <line x1="-60.1" y1="151.8" x2="53.4" y2="152"/>
              <line x1="-56.3" y1="132.7" x2="-60.1" y2="151.8"/>
              <line x1="-78" y1="142.4" x2="-56.3" y2="132.7"/>
              <line x1="-77.4" y1="22.1" x2="-78" y2="142.4"/>
              <line x1="-56.3" y1="30.1" x2="-77.4" y2="22.1"/>
              <line x1="-61.3" y1="10.8" x2="-56.3" y2="30.1"/>
              <line x1="48.4" y1="30.1" x2="-56.3" y2="30.1"/>
              <line x1="-56.5" y1="133.6" x2="-56.3" y2="30.1"/>
              <line x1="48.8" y1="132.7" x2="-56.5" y2="133.6"/>
              <line x1="48.4" y1="30.1" x2="48.8" y2="132.7"/>
              <line x1="-3.8" y1="30.1" x2="76.7" y2="2.9"/>
              <line x1="48.8" y1="81.4" x2="76.7" y2="2.9"/>
              <line x1="76.7" y1="159.9" x2="48.8" y2="81.4"/>
              <line x1="-3.8" y1="132.7" x2="76.7" y2="159.9"/>
              <line x1="-84.2" y1="159.9" x2="-3.8" y2="132.7"/>
              <line x1="-56.3" y1="81.4" x2="-84.2" y2="159.9"/>
              <line x1="-84.2" y1="2.9" x2="-56.3" y2="81.4"/>
              <line x1="-3.8" y1="30.1" x2="-84.2" y2="2.9"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="-8 0 184.62 184.62" version="1.1" class="shapeheart" v-if="diamond.item.cut === 'Heart'">
            <g id="heart" transform="translate(21.000000, 11.000000)" class="shapecolors">
              <path d="M71.1,159.9c38.8-48.5,66.6-83.2,75-93.4c0.4-0.5,1.6-2,2.8-4.1c6.1-11,4.1-25.8-1-35.8c-7.5-14.8-25.1-25.9-44-23.2c-21.5,3.1-31.6,22-32.5,23.9c-0.8-1.6-1.9-3.7-3.6-5.9C59.6,10.7,43.4,2.2,26.5,5.2c-0.8,0.1-1.6,0.3-2.4,0.5C5.7,10.4-6.9,30.9-7.3,47.4c-0.2,8.3,2.7,19.3,7.9,28.6c1.2,2.1,2.3,3.8,3.1,4.9L71.1,159.9z"/>
              <line x1="33.3" y1="27.5" x2="71.1" y2="41.2"/>
              <line x1="111.6" y1="27.5" x2="71.1" y2="41.2"/>
              <line x1="122.5" y1="5.1" x2="111.6" y2="27.5"/>
              <line x1="148.9" y1="28.8" x2="111.6" y2="27.5"/>
              <line x1="127.7" y1="51.2" x2="148.9" y2="28.8"/>
              <line x1="105.3" y1="62.9" x2="127.7" y2="51.2"/>
              <line x1="133.2" y1="82.2" x2="127.7" y2="51.2"/>
              <line x1="106.8" y1="93.6" x2="133.2" y2="82.2"/>
              <line x1="105.3" y1="62.9" x2="106.8" y2="93.6"/>
              <line x1="86.3" y1="102.5" x2="106.8" y2="93.6"/>
              <line x1="107.8" y1="114" x2="106.8" y2="93.6"/>
              <line x1="86" y1="125.6" x2="107.8" y2="114"/>
              <line x1="86.3" y1="102.5" x2="86" y2="125.6"/>
              <line x1="92.5" y1="133.1" x2="86" y2="125.6"/>
              <line x1="58.8" y1="103.1" x2="86" y2="125.6"/>
              <line x1="86.3" y1="102.5" x2="58.4" y2="125.6"/>
              <line x1="36.8" y1="92.9" x2="58.8" y2="103.1"/>
              <line x1="58.4" y1="125.6" x2="58.8" y2="103.1"/>
              <line x1="35.3" y1="117.9" x2="58.4" y2="125.6"/>
              <line x1="36.8" y1="92.9" x2="35.3" y2="117.9"/>
              <line x1="37.6" y1="63.1" x2="36.8" y2="92.9"/>
              <line x1="15.8" y1="51.2" x2="37.6" y2="63.1"/>
              <line x1="6.7" y1="84.3" x2="15.8" y2="51.2"/>
              <line x1="36.8" y1="92.9" x2="6.7" y2="84.3"/>
              <line x1="71.1" y1="159.9" x2="58.4" y2="125.6"/>
              <line x1="86" y1="125.6" x2="71.1" y2="159.9"/>
              <line x1="86.3" y1="102.5" x2="117" y2="38.5"/>
              <line x1="71.1" y1="32.5" x2="117" y2="38.5"/>
              <line x1="25.2" y1="38.5" x2="71.1" y2="32.5"/>
              <line x1="58.8" y1="103.1" x2="25.2" y2="38.5"/>
              <line x1="-5.9" y1="58.8" x2="15.8" y2="51.2"/>
              <line x1="33.3" y1="27.5" x2="15.8" y2="51.2"/>
              <line x1="-1.7" y1="27.5" x2="15.8" y2="51.2"/>
              <line x1="33.3" y1="27.5" x2="-1.7" y2="27.5"/>
              <line x1="22.2" y1="6.2" x2="33.3" y2="27.5"/>
              <line x1="50.1" y1="8" x2="33.3" y2="27.5"/>
              <line x1="71.1" y1="32.5" x2="50.1" y2="8"/>
              <line x1="93.7" y1="6.2" x2="71.1" y2="32.5"/>
              <line x1="111.6" y1="27.5" x2="93.7" y2="6.2"/>
              <line x1="117" y1="38.5" x2="111.6" y2="27.5"/>
              <line x1="71.3" y1="27.3" x2="71.1" y2="41.2"/>
              <line x1="58.4" y1="125.6" x2="50.1" y2="135.3"/>
              <line x1="127.7" y1="51.2" x2="150.5" y2="58.7"/>
              <line x1="117" y1="38.5" x2="127.7" y2="51.2"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="-4 0 184.62 184.62" version="1.1" class="shapecushion" v-if="diamond.item.cut === 'Cushion'">
            <g id="cushion" transform="translate(21.000000, 11.000000)" class="shapecolors">
              <path d="M141,154.2c-4.8,3.6-10.7,5.7-17.1,5.7H21.3c-15.7,0-28.6-12.8-28.6-28.4v-100c0-15.6,12.9-28.4,28.6-28.4h102.6c15.7,0,28.6,12.8,28.6,28.4v100.1C152.5,140.7,148,149,141,154.2"/>
              <line x1="-7.3" y1="81.4" x2="14.2" y2="124.8"/>
              <line x1="134.2" y1="37.5" x2="152.6" y2="32.2"/>
              <line x1="14.2" y1="124.8" x2="35.2" y2="114.9"/>
              <line x1="132.5" y1="125" x2="152.4" y2="132.9"/>
              <line x1="115.2" y1="141.8" x2="117.5" y2="159.8"/>
              <line x1="30" y1="142" x2="25.9" y2="159.8"/>
              <line x1="26.3" y1="2.9" x2="35.5" y2="42.3"/>
              <line x1="-7.3" y1="33.7" x2="35.5" y2="42.3"/>
              <line x1="-1.8" y1="14.7" x2="9.9" y2="37.5"/>
              <line x1="30" y1="18.1" x2="2.6" y2="9.9"/>
              <line x1="143.6" y1="152" x2="132.5" y2="125"/>
              <line x1="110.8" y1="113.8" x2="132.5" y2="125"/>
              <line x1="30" y1="142" x2="110.8" y2="113.8"/>
              <line x1="65" y1="159.9" x2="30" y2="142"/>
              <line x1="139.6" y1="155.5" x2="115.2" y2="141.8"/>
              <line x1="110.8" y1="113.8" x2="115.2" y2="141.8"/>
              <line x1="119.9" y1="81.2" x2="110.8" y2="113.8"/>
              <line x1="110.5" y1="42" x2="119.9" y2="81.2"/>
              <line x1="30" y1="18.1" x2="110.5" y2="42"/>
              <line x1="115.2" y1="141.8" x2="35.2" y2="114.9"/>
              <line x1="78.7" y1="159.9" x2="115.2" y2="141.8"/>
              <line x1="-7.3" y1="132.3" x2="14.2" y2="124.8"/>
              <line x1="2.9" y1="153.1" x2="14.2" y2="124.8"/>
              <line x1="30" y1="142" x2="5.7" y2="155.3"/>
              <line x1="35.2" y1="114.9" x2="30" y2="142"/>
              <line x1="25.9" y1="81.2" x2="35.2" y2="114.9"/>
              <line x1="35.5" y1="42.3" x2="25.9" y2="81.2"/>
              <line x1="140.5" y1="8.3" x2="116.4" y2="16.2"/>
              <line x1="35.5" y1="42.3" x2="116.4" y2="16.2"/>
              <line x1="134.2" y1="37.5" x2="145.5" y2="12.8"/>
              <line x1="119.9" y1="81.2" x2="134.2" y2="37.5"/>
              <line x1="152.5" y1="82.3" x2="134.2" y2="37.5"/>
              <line x1="132.5" y1="125" x2="152.5" y2="82.3"/>
              <line x1="119.9" y1="81.2" x2="132.5" y2="125"/>
              <line x1="-7.3" y1="81.4" x2="9.9" y2="37.5"/>
              <line x1="25.9" y1="81.2" x2="9.9" y2="37.5"/>
              <line x1="14.2" y1="124.8" x2="25.9" y2="81.2"/>
              <path d="M110.5,42l9.1-39.1"/>
              <path d="M65,2.9L30,18.1"/>
              <path d="M81.1,2.9l35.4,13.3"/>
              <path d="M134.2,37.5L110.5,42"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="-12 0 184.62 184.62" version="1.1" class="shapeoval" v-if="diamond.item.cut === 'Oval'">
            <g id="oval" transform="translate(27.000000, 11.000000)" class="shapecolors">
              <path d="M118.2,81.4c0,43.3-23.5,78.5-52.5,78.5s-52.5-35.1-52.5-78.5S36.7,2.9,65.7,2.9S118.2,38.1,118.2,81.4z"/>
              <line x1="92.9" y1="148.5" x2="82.7" y2="135.4"/>
              <line x1="86.4" y1="109" x2="82.7" y2="135.4"/>
              <line x1="103.1" y1="99.9" x2="86.4" y2="109"/>
              <line x1="27.5" y1="99.9" x2="44.1" y2="110.1"/>
              <line x1="49.8" y1="135.4" x2="44.1" y2="110.1"/>
              <line x1="37" y1="147.1" x2="49.8" y2="135.4"/>
              <line x1="116.7" y1="99.8" x2="103.1" y2="99.9"/>
              <line x1="103.8" y1="135.4" x2="103.1" y2="99.9"/>
              <line x1="82.7" y1="135.4" x2="103.8" y2="135.4"/>
              <line x1="14.6" y1="99.8" x2="27.5" y2="99.9"/>
              <line x1="27.5" y1="135.4" x2="27.5" y2="99.9"/>
              <line x1="49.8" y1="135.4" x2="27.5" y2="135.4"/>
              <line x1="25.3" y1="56.1" x2="41.9" y2="52.4"/>
              <line x1="65.7" y1="37.2" x2="41.9" y2="52.4"/>
              <line x1="87.8" y1="52.4" x2="65.7" y2="37.2"/>
              <line x1="106" y1="56.1" x2="87.8" y2="52.4"/>
              <line x1="90.8" y1="78.5" x2="106" y2="56.1"/>
              <line x1="118.1" y1="78" x2="106" y2="56.1"/>
              <line x1="103.1" y1="99.9" x2="118.1" y2="78"/>
              <line x1="90.8" y1="78.5" x2="103.1" y2="99.9"/>
              <line x1="25.3" y1="56.1" x2="38.6" y2="78.5"/>
              <line x1="27.5" y1="99.9" x2="38.6" y2="78.5"/>
              <line x1="13.2" y1="81.4" x2="27.5" y2="99.9"/>
              <line x1="25.3" y1="56.1" x2="13.2" y2="81.4"/>
              <line x1="84.4" y1="24" x2="90.8" y2="78.5"/>
              <line x1="86.4" y1="109" x2="90.8" y2="78.5"/>
              <line x1="49.8" y1="135.4" x2="86.4" y2="109"/>
              <line x1="65.7" y1="159.9" x2="82.7" y2="135.4"/>
              <line x1="49.8" y1="135.4" x2="65.7" y2="159.9"/>
              <line x1="82.7" y1="135.4" x2="44.1" y2="110.1"/>
              <line x1="38.6" y1="78.5" x2="44.1" y2="110.1"/>
              <line x1="45.8" y1="21.6" x2="38.6" y2="78.5"/>
              <line x1="93.3" y1="14.8" x2="84.4" y2="24"/>
              <line x1="106" y1="31.2" x2="84.4" y2="24"/>
              <line x1="106" y1="56.1" x2="106" y2="31.2"/>
              <line x1="115.4" y1="56.1" x2="106" y2="56.1"/>
              <line x1="38" y1="14.8" x2="45.8" y2="21.6"/>
              <line x1="25.3" y1="31.2" x2="45.8" y2="21.6"/>
              <line x1="25.3" y1="56.1" x2="25.3" y2="31.2"/>
              <line x1="16" y1="56.1" x2="25.3" y2="56.1"/>
              <line x1="84.4" y1="24" x2="65.7" y2="2.9"/>
              <line x1="45.8" y1="21.6" x2="65.7" y2="2.9"/>
              <line x1="65.7" y1="37.2" x2="45.8" y2="21.6"/>
              <line x1="84.4" y1="24" x2="65.7" y2="37.2"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="-8 0 184.62 184.62" version="1.1" class="shapeemerald" v-if="diamond.item.cut === 'Emerald'">
            <g id="emerald" transform="translate(26.000000, 11.000000)" class="shapecolors">
              <polygon points="103.93,159.87 27.69,159.87 8.5,142.01 8.5,22.44 26.85,2.95 106.04,2.95 125.16,22.97 125.16,142.01"/>
              <line x1="46.3" y1="31.9" x2="87" y2="31.9"/>
              <line x1="96.8" y1="44.6" x2="87" y2="31.9"/>
              <line x1="46.3" y1="31.9" x2="36.9" y2="44.2"/>
              <line x1="36.9" y1="119.6" x2="36.9" y2="44.2"/>
              <line x1="46.7" y1="130.9" x2="36.9" y2="119.6"/>
              <line x1="85.9" y1="130.9" x2="46.7" y2="130.9"/>
              <line x1="96.8" y1="119.6" x2="85.9" y2="130.9"/>
              <line x1="96.8" y1="44.6" x2="96.8" y2="119.6"/>
              <line x1="93.4" y1="22.6" x2="39.7" y2="22.6"/>
              <line x1="106.4" y1="37.6" x2="93.4" y2="22.6"/>
              <line x1="106.4" y1="126.8" x2="106.4" y2="37.6"/>
              <line x1="92" y1="140.2" x2="106.4" y2="126.8"/>
              <line x1="40.3" y1="140.2" x2="92" y2="140.2"/>
              <line x1="27.2" y1="126.9" x2="40.3" y2="140.2"/>
              <line x1="27.2" y1="37.2" x2="27.2" y2="126.9"/>
              <line x1="39.7" y1="22.6" x2="27.2" y2="37.2"/>
              <line x1="34.9" y1="14.9" x2="98.1" y2="15.1"/>
              <line x1="113.8" y1="31.6" x2="98.1" y2="15.1"/>
              <line x1="113.8" y1="133.1" x2="113.8" y2="31.6"/>
              <line x1="96.4" y1="147.7" x2="113.8" y2="133.1"/>
              <line x1="35.5" y1="148" x2="96.4" y2="147.7"/>
              <line x1="19.5" y1="133.3" x2="35.5" y2="148"/>
              <line x1="19.3" y1="30.8" x2="19.5" y2="133.3"/>
              <line x1="34.9" y1="14.9" x2="19.3" y2="30.8"/>
              <path d="M87,31.9l19.1-29"/>
              <path d="M96.8,44.6L125.2,23"/>
              <path d="M26.9,2.9l19.4,29"/>
              <path d="M8.5,22.4l28.4,21.8"/>
              <path d="M8.5,142l28.4-22.4"/>
              <path d="M46.7,130.9l-19,29"/>
              <path d="M85.9,130.9l18.1,29"/>
              <path d="M96.8,119.6l28.4,22.4"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="-5 0 184.62 184.62" version="1.1" class="shapeasscher" v-if="diamond.item.cut === 'Asscher'">
            <g id="asscher" transform="translate(22.000000, 11.000000)" class="shapecolors">
              <polygon id="Stroke-1_1_" points="26.1,2.9 -9,37.6 -9,123.4 26.1,159.9 113.6,159.9 151,125.2 151,38.5 115.3,2.9"/>
              <line x1="37.1" y1="23" x2="103.5" y2="23"/>
              <line x1="130.1" y1="49.2" x2="103.5" y2="23"/>
              <line x1="130.1" y1="115.3" x2="130.1" y2="49.2"/>
              <line x1="103.5" y1="143.6" x2="130.1" y2="115.3"/>
              <line x1="35.2" y1="143.6" x2="103.5" y2="143.6"/>
              <line x1="9.5" y1="115.3" x2="35.2" y2="143.6"/>
              <line x1="9.5" y1="48.2" x2="9.5" y2="115.3"/>
              <line x1="37.1" y1="23" x2="9.5" y2="48.2"/>
              <line x1="42.9" y1="33.5" x2="96.9" y2="33.5"/>
              <line x1="119.4" y1="55.6" x2="96.9" y2="33.5"/>
              <line x1="119.4" y1="109.8" x2="119.4" y2="55.6"/>
              <line x1="96.1" y1="131.6" x2="119.4" y2="109.8"/>
              <line x1="43.5" y1="131.6" x2="96.1" y2="131.6"/>
              <line x1="20.9" y1="109.2" x2="43.5" y2="131.6"/>
              <line x1="20.9" y1="55.5" x2="20.9" y2="109.2"/>
              <line x1="42.9" y1="33.5" x2="20.9" y2="55.5"/>
              <line x1="48" y1="43" x2="91.8" y2="43"/>
              <line x1="109.3" y1="61.1" x2="91.8" y2="43"/>
              <line x1="109.3" y1="105.4" x2="109.3" y2="61.1"/>
              <line x1="90.9" y1="123.1" x2="109.3" y2="105.4"/>
              <line x1="48" y1="123.1" x2="90.9" y2="123.1"/>
              <line x1="30.8" y1="104.5" x2="48" y2="123.1"/>
              <line x1="30.8" y1="60.7" x2="30.8" y2="104.5"/>
              <line x1="48" y1="43" x2="30.8" y2="60.7"/>
              <line x1="91.8" y1="43" x2="115.3" y2="2.9"/>
              <line x1="109.3" y1="105.4" x2="151" y2="125.2"/>
              <line x1="90.9" y1="123.1" x2="113.5" y2="159.9"/>
              <line x1="109.3" y1="61.1" x2="151" y2="38.5"/>
              <line x1="48" y1="123.1" x2="26.1" y2="159.9"/>
              <line x1="26.1" y1="2.9" x2="48" y2="43"/>
              <line x1="30.8" y1="60.7" x2="-9" y2="37.6"/>
              <line x1="30.8" y1="104.5" x2="-9" y2="123.4"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="-5 0 184.62 184.62" version="1.1" class="shaperadiant" v-if="diamond.item.cut === 'Radiant'">
            <g id="radiant" transform="translate(26.000000, 11.000000)" class="shapecolors">
              <polygon points="25.8,2.9 8.9,20 8.9,142.6 28.5,159.9 107.3,159.9 125.2,142.6 125.2,22.8 107.8,2.9"/>
              <line x1="90" y1="130.6" x2="125.2" y2="142.6"/>
              <line x1="107.3" y1="159.9" x2="90" y2="130.6"/>
              <line x1="28.5" y1="159.9" x2="43.2" y2="130.6"/>
              <line x1="8.9" y1="142.6" x2="43.2" y2="130.6"/>
              <line x1="93.1" y1="30.1" x2="107.8" y2="2.9"/>
              <line x1="125.2" y1="22.8" x2="93.1" y2="30.1"/>
              <line x1="25.8" y1="2.9" x2="40.9" y2="32.1"/>
              <line x1="8.9" y1="20" x2="40.9" y2="32.1"/>
              <line x1="31.9" y1="14.7" x2="93.1" y2="30.1"/>
              <line x1="101.4" y1="14.7" x2="31.9" y2="14.7"/>
              <line x1="109.1" y1="26.4" x2="101.4" y2="14.7"/>
              <line x1="90" y1="130.6" x2="109.1" y2="26.4"/>
              <line x1="34.9" y1="147" x2="90" y2="130.6"/>
              <line x1="31.9" y1="14.7" x2="22.3" y2="25.1"/>
              <line x1="40.9" y1="32.1" x2="101.4" y2="14.7"/>
              <line x1="22.3" y1="137.9" x2="40.9" y2="32.1"/>
              <line x1="22.3" y1="25.1" x2="43.2" y2="130.6"/>
              <line x1="22.3" y1="137.9" x2="22.3" y2="25.1"/>
              <line x1="99.7" y1="147" x2="43.2" y2="130.6"/>
              <line x1="93.1" y1="30.1" x2="109.1" y2="137.1"/>
              <line x1="109.1" y1="26.4" x2="109.1" y2="137.1"/>
              <line x1="22.3" y1="137.9" x2="34.9" y2="147"/>
              <line x1="109.1" y1="137.1" x2="99.7" y2="147"/>
              <line x1="34.9" y1="147" x2="99.7" y2="147"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="-8 0 184.62 184.62" version="1.1" class="shapepear" v-if="diamond.item.cut === 'Pear'">
            <g id="pear" transform="translate(27.000000, 11.000000)" class="shapecolors">
              <path d="M116.3,81.1c1.7,12.7,4.9,35.9-9.8,55.6c-4.3,5.8-18.6,22.6-41.1,22.6c-23.2,0-37.7-18.1-41.9-24c-13.7-19.5-10.7-41.6-9-54.1C18.8,50.3,65.4,2.9,65.4,2.9S112.1,50.1,116.3,81.1z"/>
              <line x1="76.5" y1="47.9" x2="93.6" y2="68"/>
              <line x1="95.2" y1="91" x2="93.6" y2="68"/>
              <line x1="82.3" y1="122.8" x2="95.2" y2="91"/>
              <line x1="82.3" y1="140.6" x2="82.3" y2="122.8"/>
              <line x1="65.7" y1="22.9" x2="65.4" y2="2.9"/>
              <line x1="54.4" y1="15" x2="65.7" y2="22.9"/>
              <line x1="37.2" y1="68" x2="55.1" y2="47.9"/>
              <line x1="18.4" y1="68" x2="37.2" y2="68"/>
              <line x1="65.7" y1="22.9" x2="37.2" y2="36.3"/>
              <line x1="37.2" y1="68" x2="37.2" y2="36.3"/>
              <line x1="37.2" y1="90.3" x2="37.2" y2="68"/>
              <line x1="47.8" y1="122.7" x2="37.2" y2="90.3"/>
              <line x1="47.1" y1="140.2" x2="47.8" y2="122.7"/>
              <line x1="65.7" y1="22.9" x2="93.6" y2="36.3"/>
              <line x1="93.6" y1="68" x2="93.6" y2="36.3"/>
              <line x1="112.5" y1="68" x2="93.6" y2="68"/>
              <line x1="117.8" y1="96.6" x2="93.6" y2="68"/>
              <line x1="102.6" y1="112.8" x2="117.8" y2="96.6"/>
              <line x1="13.1" y1="95.4" x2="37.2" y2="68"/>
              <line x1="28.8" y1="112.8" x2="13.1" y2="95.4"/>
              <line x1="116.4" y1="114" x2="102.6" y2="112.8"/>
              <line x1="107.7" y1="135.1" x2="102.6" y2="112.8"/>
              <line x1="82.3" y1="140.6" x2="107.7" y2="135.1"/>
              <line x1="92.4" y1="150.4" x2="82.3" y2="140.6"/>
              <line x1="14.8" y1="115.6" x2="28.8" y2="112.8"/>
              <line x1="23.6" y1="135.2" x2="28.8" y2="112.8"/>
              <line x1="47.1" y1="140.2" x2="23.6" y2="135.2"/>
              <line x1="40" y1="151.3" x2="47.1" y2="140.2"/>
              <line x1="95.2" y1="91" x2="102.6" y2="112.8"/>
              <line x1="82.3" y1="122.8" x2="102.6" y2="112.8"/>
              <line x1="47.1" y1="140.2" x2="82.3" y2="122.8"/>
              <line x1="65.4" y1="159.3" x2="47.1" y2="140.2"/>
              <line x1="82.3" y1="140.6" x2="65.4" y2="159.3"/>
              <line x1="47.8" y1="122.7" x2="82.3" y2="140.6"/>
              <line x1="28.8" y1="112.8" x2="47.8" y2="122.7"/>
              <line x1="37.2" y1="90.3" x2="28.8" y2="112.8"/>
              <line x1="65.7" y1="22.9" x2="95.2" y2="91"/>
              <line x1="65.7" y1="22.9" x2="76.5" y2="15"/>
              <line x1="65.7" y1="22.9" x2="37.2" y2="90.3"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="-10 0 184.62 184.62" version="1.1" class="shapemarquise" v-if="diamond.item.cut === 'Marquise'">
            <g id="marquise" transform="translate(30.000000, 11.000000)" class="shapecolors">
              <path d="M103.7,81.3c0,43.4-40.5,78.6-40.5,78.6s-40.5-35.2-40.5-78.6S63.2,2.6,63.2,2.6S103.7,37.8,103.7,81.3z"/>
              <path d="M76.5,145.1l-6.2-7.7"/>
              <path d="M88.2,62.9l12.2-4"/>
              <line x1="63.2" y1="2.6" x2="72.4" y2="28.8"/>
              <line x1="91.3" y1="38.1" x2="72.4" y2="28.8"/>
              <line x1="88.2" y1="62.9" x2="91.3" y2="38.1"/>
              <line x1="82" y1="84.4" x2="88.2" y2="62.9"/>
              <line x1="72.8" y1="116.4" x2="82" y2="84.4"/>
              <line x1="70.6" y1="137.8" x2="72.8" y2="116.4"/>
              <line x1="63.2" y1="159.9" x2="70.6" y2="137.8"/>
              <line x1="55.6" y1="137.4" x2="63.2" y2="159.9"/>
              <line x1="85" y1="102" x2="100.3" y2="103.5"/>
              <line x1="26.5" y1="58" x2="39.3" y2="61.8"/>
              <line x1="35.1" y1="38.1" x2="39.3" y2="61.8"/>
              <line x1="54.3" y1="28.8" x2="35.1" y2="38.1"/>
              <line x1="63.2" y1="2.6" x2="54.3" y2="28.8"/>
              <line x1="72.4" y1="28.8" x2="22.8" y2="78.3"/>
              <line x1="70.6" y1="137.8" x2="22.8" y2="78.3"/>
              <line x1="90.4" y1="126" x2="70.6" y2="137.8"/>
              <line x1="72.4" y1="28.8" x2="90.4" y2="126"/>
              <line x1="54.3" y1="28.8" x2="38.3" y2="129.8"/>
              <line x1="103.7" y1="78.6" x2="54.3" y2="28.8"/>
              <line x1="55.6" y1="137.4" x2="103.7" y2="78.6"/>
              <line x1="38.3" y1="129.8" x2="55.6" y2="137.4"/>
              <line x1="49.3" y1="145.1" x2="55.6" y2="137.4"/>
              <line x1="39.3" y1="61.8" x2="45.3" y2="85.2"/>
              <line x1="53.4" y1="116.4" x2="45.3" y2="85.2"/>
              <line x1="55.6" y1="137.4" x2="53.4" y2="116.4"/>
              <line x1="42.6" y1="102.8" x2="26.7" y2="105.2"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="-5 0 184.62 184.62" version="1.1" class="shapetrilliant" v-if="diamond.item.cut === 'Trilliant'">
            <g id="trilliant" class="shapecolors">
              <path d="M93.28,13.65c34.67,15.43,76.11,100.92,80.34,139.71c-21.99,23.77-143.34,22.94-160.67,0C17.18,114.57,58.61,29.08,93.28,13.65"/>
              <line x1="153.7" y1="129.2" x2="167.3" y2="126.3"/>
              <line x1="32.8" y1="129.2" x2="19.3" y2="125.8"/>
              <line x1="133.9" y1="168" x2="138.9" y2="147.9"/>
              <line x1="51" y1="167.9" x2="47.2" y2="147.9"/>
              <line x1="93.3" y1="140.4" x2="153.7" y2="129.2"/>
              <line x1="143.9" y1="74.5" x2="153.7" y2="129.2"/>
              <line x1="92.9" y1="55.3" x2="143.9" y2="74.5"/>
              <line x1="122.9" y1="41.2" x2="92.9" y2="55.3"/>
              <line x1="92.9" y1="140.4" x2="32.8" y2="129.2"/>
              <line x1="42.5" y1="74.5" x2="32.8" y2="129.2"/>
              <line x1="92.9" y1="55.3" x2="42.5" y2="74.5"/>
              <line x1="63.6" y1="41.2" x2="92.9" y2="55.3"/>
              <line x1="138.9" y1="147.9" x2="92.9" y2="170.9"/>
              <line x1="47.2" y1="147.9" x2="92.9" y2="170.9"/>
              <line x1="138.9" y1="147.9" x2="173.6" y2="153.4"/>
              <line x1="47.2" y1="147.9" x2="12.9" y2="153.4"/>
              <line x1="92.9" y1="55.3" x2="13.8" y2="153.2"/>
              <line x1="173.6" y1="153.4" x2="92.9" y2="55.3"/>
              <line x1="92.9" y1="140.4" x2="138.9" y2="147.9"/>
              <line x1="93.3" y1="13.6" x2="138.9" y2="147.9"/>
              <line x1="47.2" y1="147.9" x2="93.3" y2="140.4"/>
              <line x1="93.3" y1="13.6" x2="47.2" y2="147.9"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="0 0 184.62 184.62" version="1.1" class="shapetriangle" v-if="diamond.item.cut === 'Triangle'">
            <g id="triangle" class="shapecolors">
              <polygon points="92.9,13.7 2.5,171 183.2,171"/>
              <line x1="92.9" y1="89.2" x2="92.9" y2="13.7"/>
              <line x1="118" y1="133.1" x2="183.2" y2="171"/>
              <line x1="67.7" y1="133.1" x2="2.5" y2="171"/>
              <line x1="92.9" y1="70.2" x2="134.6" y2="142.7"/>
              <line x1="51.3" y1="142.7" x2="134.6" y2="142.7"/>
              <line x1="92.9" y1="70.2" x2="51.3" y2="142.7"/>
              <line x1="92.9" y1="89.2" x2="118" y2="133.1"/>
              <line x1="67.7" y1="133.1" x2="118" y2="133.1"/>
              <line x1="92.9" y1="89.2" x2="67.7" y2="133.1"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="0 0 184.62 184.62" version="1.1" class="shapebaguette" v-if="diamond.item.cut === 'baguette'">
            <g id="baguette" transform="translate(346.000000, 129.000000)" class="shapecolors">
              <polygon points="-293.2,-115.1 -214.9,-115.1 -214.9,41.9 -293.2,41.9"/>
              <path d="M-292.7-115.1l11,11.4"/>
              <path d="M-214.9-115.1l-12,11.4"/>
              <path d="M-292.7,41.9l11-10.6"/>
              <path d="M-226.9,31.3l12,10.6"/>
              <line x1="-226.9" y1="-103.6" x2="-281.7" y2="-103.6"/>
              <line x1="-226.9" y1="31.3" x2="-281.7" y2="31.3"/>
              <line x1="-281.7" y1="-103.6" x2="-281.7" y2="31.3"/>
              <line x1="-226.9" y1="-103.6" x2="-226.9" y2="31.3"/>
              <line x1="-221.1" y1="-109.1" x2="-221.1" y2="36.8"/>
              <line x1="-287.4" y1="-109.1" x2="-287.4" y2="36.8"/>
              <line x1="-287.4" y1="36.8" x2="-221.1" y2="36.8"/>
              <line x1="-287.4" y1="-109.1" x2="-221.1" y2="-109.1"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="0 0 184.62 184.62" version="1.1" class="shaperose" v-if="diamond.item.cut === 'Rose'">
            <g id="rose" class="shapecolors">
              <path id="path" d="M171.6,92c0,43.7-35.4,79.1-79.1,79.1c-43.7,0-79.1-35.4-79.1-79.1c0-43.7,35.4-79.1,79.1-79.1C136.2,12.9,171.6,48.3,171.6,92z"/>
              <line x1="40.6" y1="80" x2="142.9" y2="104.4"/>
              <line x1="78.7" y1="13.9" x2="103.2" y2="40.9"/>
              <line x1="138" y1="27.3" x2="103.2" y2="40.9"/>
              <line x1="136.3" y1="64.7" x2="138" y2="27.3"/>
              <line x1="170.6" y1="79.4" x2="136.3" y2="64.7"/>
              <line x1="142.9" y1="104.4" x2="170.6" y2="79.4"/>
              <line x1="156.5" y1="138.4" x2="142.9" y2="104.4"/>
              <line x1="120.2" y1="136.6" x2="156.5" y2="138.4"/>
              <line x1="106.4" y1="169.8" x2="120.2" y2="136.6"/>
              <line x1="80.3" y1="143.3" x2="106.4" y2="169.8"/>
              <line x1="47.3" y1="156.9" x2="80.3" y2="143.3"/>
              <line x1="47.5" y1="121.3" x2="47.3" y2="156.9"/>
              <line x1="14.3" y1="105" x2="47.6" y2="120.3"/>
              <line x1="40.6" y1="80" x2="14.3" y2="105"/>
              <line x1="28.1" y1="46" x2="40.6" y2="80"/>
              <line x1="64.5" y1="46.9" x2="28.1" y2="46"/>
              <line x1="78.7" y1="13.9" x2="64.5" y2="46.9"/>
              <line x1="103.2" y1="40.9" x2="64.5" y2="46.9"/>
              <line x1="40.6" y1="80" x2="64.5" y2="46.9"/>
              <line x1="47.6" y1="120.3" x2="40.6" y2="80"/>
              <line x1="80.3" y1="143.3" x2="47.6" y2="120.3"/>
              <line x1="120.2" y1="136.6" x2="80.3" y2="143.3"/>
              <line x1="142.9" y1="104.4" x2="120.2" y2="136.6"/>
              <line x1="136.3" y1="64.7" x2="142.9" y2="104.4"/>
              <line x1="103.2" y1="40.9" x2="136.3" y2="64.7"/>
              <line x1="80.3" y1="143.3" x2="103.2" y2="40.9"/>
              <line x1="64.5" y1="46.9" x2="120.2" y2="136.6"/>
              <line x1="136.3" y1="64.7" x2="47.6" y2="120.3"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="0 0 184.62 184.62" version="1.1" class="shapehalf-moon" v-if="diamond.item.cut === 'Half Moon'">
            <g id="half-moon" class="shapecolors">
              <path id="Half-Moon" d="M33.9,142.9l134.1-0.3c2.4-10.3,3.6-18.1,3.6-23.6c0-43.7-35.4-79.1-79.1-79.1S13.4,75.4,13.4,119c0,8.3,1.3,16.4,3.7,23.9H33.9z"/>
              <line x1="92.5" y1="54.8" x2="92.5" y2="40"/>
              <line x1="43.6" y1="105" x2="92.5" y2="54.8"/>
              <line x1="47.2" y1="72.7" x2="43.6" y2="105"/>
              <line x1="37.6" y1="131.8" x2="43.6" y2="105"/>
              <line x1="50.7" y1="120.9" x2="43.6" y2="105"/>
              <line x1="37.6" y1="131.8" x2="50.7" y2="120.9"/>
              <line x1="134.4" y1="120.9" x2="50.7" y2="120.9"/>
              <line x1="92.5" y1="54.8" x2="141.6" y2="105.6"/>
              <line x1="134.4" y1="120.9" x2="141.6" y2="105.6"/>
              <line x1="92.5" y1="54.8" x2="122.2" y2="46.3"/>
              <line x1="138.1" y1="72.7" x2="122.2" y2="46.3"/>
              <line x1="47.2" y1="72.7" x2="137.2" y2="72.7"/>
              <line x1="147.5" y1="62.2" x2="137.2" y2="72.7"/>
              <line x1="164.3" y1="87" x2="137.2" y2="72.7"/>
              <line x1="141.6" y1="105.6" x2="138.1" y2="72.7"/>
              <line x1="164.3" y1="87" x2="141.6" y2="105.6"/>
              <line x1="171.6" y1="117" x2="141.6" y2="105.6"/>
              <line x1="168" y1="142.6" x2="141.6" y2="105.6"/>
              <line x1="147.5" y1="131.8" x2="142.3" y2="106.6"/>
              <line x1="134.4" y1="120.9" x2="147.5" y2="131.8"/>
              <line x1="168" y1="142.6" x2="147.5" y2="131.8"/>
              <line x1="37.6" y1="131.8" x2="148.5" y2="131.5"/>
              <line x1="43.6" y1="105" x2="17.1" y2="142.9"/>
              <line x1="37.6" y1="131.8" x2="17.1" y2="142.9"/>
              <line x1="43.6" y1="105" x2="13.4" y2="117.9"/>
              <line x1="20.4" y1="87" x2="43.6" y2="105"/>
              <line x1="47.2" y1="72.7" x2="20.4" y2="87"/>
              <line x1="37.6" y1="62.2" x2="47.2" y2="72.7"/>
              <line x1="63.4" y1="45.8" x2="47.2" y2="72.7"/>
              <line x1="92.5" y1="54.8" x2="63.4" y2="45.8"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="0 0 184.62 184.62" version="1.1" class="shapehexagon" v-if="diamond.item.cut === 'Hexagon'">
            <g id="Hexagon" class="shapecolors">
              <polygon points="160.3,53.5 160.5,131.6 92.8,170.4 24.8,131.1 24.6,53.1 92.3,14.3"/>
              <line x1="49.5" y1="66.8" x2="92.8" y2="42.4"/>
              <line x1="134.8" y1="66.8" x2="92.8" y2="42.4"/>
              <line x1="134.8" y1="117.4" x2="134.8" y2="66.8"/>
              <line x1="49.5" y1="66.8" x2="49.5" y2="117.4"/>
              <line x1="92.2" y1="142.1" x2="49.5" y2="117.4"/>
              <line x1="134.8" y1="117.4" x2="92.2" y2="142.1"/>
              <line x1="159.7" y1="53.3" x2="134.8" y2="66.8"/>
              <line x1="92.8" y1="27.6" x2="147.7" y2="59.9"/>
              <line x1="147.7" y1="123.9" x2="147.7" y2="59.9"/>
              <line x1="92.5" y1="155.9" x2="147.7" y2="123.9"/>
              <line x1="36.7" y1="123.9" x2="92.8" y2="156.2"/>
              <line x1="36.7" y1="59.9" x2="36.7" y2="123.9"/>
              <line x1="92.8" y1="27.6" x2="36.7" y2="59.9"/>
              <line x1="92.8" y1="42.4" x2="92.8" y2="13.9"/>
              <line x1="49.5" y1="66.8" x2="25.5" y2="52.6"/>
              <line x1="49.5" y1="117.4" x2="25.5" y2="131.2"/>
              <line x1="134.8" y1="117.4" x2="160.1" y2="131.2"/>
              <line x1="92.2" y1="142.1" x2="92.8" y2="170.9"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="0 0 184.62 184.62" version="1.1" class="shapetaperbaguette" v-if="diamond.item.cut === 'Taper Baguette'">
            <g id="Taper_Baguette" class="shapecolors">
              <polygon points="113.4,14.6 68.6,14.6 55.2,169.5 127,169.5"/>
              <line x1="84.3" y1="43.6" x2="96.6" y2="43.3"/>
              <line x1="102.5" y1="142.2" x2="96.6" y2="43.3"/>
              <line x1="78.8" y1="141.8" x2="84.3" y2="43.6"/>
              <line x1="102.5" y1="142.2" x2="78.8" y2="141.8"/>
              <line x1="55.2" y1="169.5" x2="78.8" y2="141.8"/>
              <line x1="102.5" y1="142.2" x2="127.2" y2="169.5"/>
              <line x1="96.6" y1="43.3" x2="113.4" y2="14.6"/>
              <line x1="84.3" y1="43.6" x2="68.6" y2="14.6"/>
              <line x1="74.1" y1="24.6" x2="107.4" y2="24.8"/>
              <line x1="79.8" y1="35.3" x2="101.2" y2="35.4"/>
              <line x1="119" y1="160.5" x2="63.5" y2="160.1"/>
              <line x1="107.4" y1="24.8" x2="119" y2="160.5"/>
              <line x1="79.8" y1="35.3" x2="71.5" y2="150.4"/>
              <line x1="109.7" y1="150.2" x2="71.5" y2="150.4"/>
              <line x1="101.2" y1="35.4" x2="109.7" y2="150.2"/>
              <line x1="74.1" y1="24.6" x2="63.5" y2="160.1"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="0 0 184.62 184.62" version="1.1" class="shapeshield" v-if="diamond.item.cut === 'Shield'">
            <g id="Shield" class="shapecolors">
              <path d="M165.3,44c0,0-23.1,63.5-73.8,125.5c0,0-43.8-51-72.7-125.9l23.1-29h100L165.3,44z"/>
              <path d="M91.4,146.6c0,0-32.5-38.5-54.4-96.7"/>
              <path d="M91.3,146.6c0,0,33.2-38.5,55.2-96.7"/>
              <line x1="146.4" y1="49.9" x2="129.8" y2="29.2"/>
              <line x1="37" y1="49.9" x2="53.9" y2="29.2"/>
              <line x1="129.8" y1="29.2" x2="53.9" y2="29.2"/>
              <line x1="141.9" y1="14.7" x2="129.8" y2="29.2"/>
              <line x1="165.3" y1="44" x2="146.4" y2="49.9"/>
              <line x1="91.5" y1="169.5" x2="91.3" y2="146.2"/>
              <line x1="53.9" y1="29.2" x2="41.9" y2="14.7"/>
              <line x1="37" y1="49.9" x2="18.8" y2="43.7"/>
            </g>
          </svg>
          <svg width="100%" x="0px" y="0px" viewBox="0 0 184.62 184.62" version="1.1" class="shapetrapeze" v-if="diamond.item.cut === 'Trapeze'">
            <g id="Trapeze" class="shapecolors">
              <polygon points="10.7,142.5 174.3,142.5 159.4,44.5 25.5,44.5"/>
              <line x1="46.8" y1="69.6" x2="137" y2="69.6"/>
              <line x1="143.3" y1="117.9" x2="40.5" y2="117.9"/>
              <line x1="46.8" y1="69.6" x2="40.5" y2="117.9"/>
              <line x1="137" y1="69.6" x2="143.3" y2="117.9"/>
              <line x1="174.3" y1="142.5" x2="143.3" y2="117.9"/>
              <line x1="159.4" y1="44.5" x2="137" y2="69.6"/>
              <line x1="25.5" y1="44.5" x2="46.8" y2="69.6"/>
              <line x1="40.5" y1="117.9" x2="10.7" y2="142.5"/>
            </g>
          </svg>
        </div>
        <div class="text-center mt-4 mb-4 cert-holder">
          <b-img v-if="diamond.item.lab === 'AGS'" :src="require('@/assets/img/lab/ags.png')" alt="AGS" fluid></b-img>
          <b-img v-if="diamond.item.lab === 'AIG'" :src="require('@/assets/img/lab/aig.png')" alt="AIG" fluid></b-img>
          <b-img v-if="diamond.item.lab === 'CGL'" :src="require('@/assets/img/lab/cgl.png')" alt="CGL" fluid></b-img>
          <b-img v-if="diamond.item.lab === 'DGI'" :src="require('@/assets/img/lab/dgi.png')" alt="DGI" fluid></b-img>
          <b-img v-if="diamond.item.lab === 'EGL'" :src="require('@/assets/img/lab/egl.png')" alt="EGL" fluid></b-img>
          <b-img v-if="diamond.item.lab === 'EGL Other'" :src="require('@/assets/img/lab/egl-other.png')" alt="EGL Other" fluid></b-img>
          <b-img v-if="diamond.item.lab === 'EGL USA'" :src="require('@/assets/img/lab/egl-usa.png')" alt="EGL USA" fluid></b-img>
          <b-img v-if="diamond.item.lab === 'FM'" :src="require('@/assets/img/lab/fm.png')" alt="FM" fluid></b-img>
          <b-img v-if="diamond.item.lab === 'GIA'" :src="require('@/assets/img/lab/gia.png')" alt="GIA" fluid></b-img>
          <b-img v-if="diamond.item.lab === 'GS'" :src="require('@/assets/img/lab/gs.png')" alt="GS" fluid></b-img>
          <b-img v-if="diamond.item.lab === 'GSI'" :src="require('@/assets/img/lab/gsi.png')" alt="GSI" fluid></b-img>
          <b-img v-if="diamond.item.lab === 'HRD'" :src="require('@/assets/img/lab/hrd.png')" alt="HRD" fluid></b-img>
          <b-img v-if="diamond.item.lab === 'IGI'" :src="require('@/assets/img/lab/igi.png')" alt="IGI" fluid></b-img>
          <b-img v-if="diamond.item.lab === 'IGL'" :src="require('@/assets/img/lab/igl.png')" alt="IGL" fluid></b-img>
        </div>
      </b-col>
      <b-col cols="12" sm="6" md="4">
        <table width="100%">
          <tr v-if="pluginSettings.multipleDiamondTypes">
            <td>{{ $t('diamond type') }}</td>
            <td>{{ $t(diamond.item.ga) }}</td>
          </tr>
          <tr v-if="diamond.item.cut !== null">
            <td>{{ $t('shape') }}</td>
            <td>{{ $t(diamond.item.cut.toLowerCase().replace(' ', '_')) }}</td>
          </tr>
          <tr v-if="diamond.item.ct !== null">
            <td>{{ $t('carat_weight') }}</td>
            <td>{{ diamond.item.ct | formatNumber(2, '.', '') }} Ct.</td>
          </tr>
          <tr v-if="diamond.item.col !== null || diamond.item.nfc !== null">
            <td>{{ $t('color') }}</td>
            <td>{{ (diamond.item.col != null) ? diamond.item.col : ((diamond.item.nfci !== undefined) ? diamond.item.nfci + ' ' : '') + diamond.item.nfc }}</td>
          </tr>
          <tr v-if="diamond.item.nfco !== null">
            <td>{{ $t('natural_fancy_overtone') }}</td>
            <td>{{ diamond.item.nfco }}</td>
          </tr>
          <tr v-if="diamond.item.cl !== null">
            <td>{{ $t('clarity_grade') }}</td>
            <td>{{ diamond.item.cl }}</td>
          </tr>
          <tr v-if="diamond.item.mk !== null">
            <td>{{ $t('cut_quality') }}</td>
            <td>{{ $t(diamond.item.mk.toLowerCase().replace(' ', '_')) }}</td>
          </tr>
          <tr v-if="diamond.item.pol !== null">
            <td>{{ $t('polish') }}</td>
            <td>{{ $t(diamond.item.pol.toLowerCase().replace(' ', '_')) }}</td>
          </tr>
          <tr v-if="diamond.item.sym !== null">
            <td>{{ $t('symmetry') }}</td>
            <td>{{ $t(diamond.item.sym.toLowerCase().replace(' ', '_')) }}</td>
          </tr>
          <tr v-if="diamond.item.fl !== null">
            <td>{{ $t('fluorescence') }}</td>
            <td>{{ $t(diamond.item.fl.toLowerCase().replace(' ', '_')) }}</td>
          </tr>
        </table>
      </b-col>
      <b-col cols="12" sm="6" md="4">
        <table width="100%">
          <tr v-if="diamond.item.mes !== null">
            <td>{{ $t('measurements') }}</td>
            <td>{{ diamond.item.mes.replace(/x/g, ' x ') }} mm</td>
          </tr>
          <tr v-if="diamond.item.dp !== null && diamond.item.dp !== NaN">
            <td>{{ $t('total_depth') }}</td>
            <td>{{ diamond.item.dp }} %</td>
          </tr>
          <tr v-if="diamond.item.tb !== null">
            <td>{{ $t('table_size') }}</td>
            <td>{{ diamond.item.tb }} %</td>
          </tr>
          <tr v-if="diamond.item.cr !== null">
            <td>{{ $t('crown_height') }}</td>
            <td>{{ diamond.item.cr }} %</td>
          </tr>
          <tr v-if="diamond.item.ca !== null">
            <td>{{ $t('crown_angle') }}</td>
            <td>{{ diamond.item.ca }} &deg;</td>
          </tr>
          <tr v-if="diamond.item.pv !== null">
            <td>{{ $t('pavilion_angle') }}</td>
            <td>{{ diamond.item.pv }}&deg;</td>
          </tr>
          <tr v-if="diamond.item.gd !== null">
            <td>{{ $t('girdle') }}</td>
            <td>{{ diamond.item.gd }}</td>
          </tr>
          <tr v-if="diamond.item.cc !== null">
            <td>{{ $t('culet') }}</td>
            <td>{{ diamond.item.cc }}</td>
          </tr>
          <tr v-if="diamond.item.lab !== null">
            <td>{{ $t('grading_report') }}</td>
            <td>{{ diamond.item.lab }} {{ diamond.item.cn }}</td>
          </tr>
        </table>
        <b-col v-if="byDiamondClicked" cols="12" class="text-center mt-3">
          <b-spinner small label="Small Spinner" type="grow"></b-spinner>
        </b-col>
        <b-button v-if="pluginSettings.shoppingCart !== null && !byDiamondClicked" @click="buyDiamond(diamond.item)" class="btn btn-primary btn-block mt-3 mb-3"><b-icon-bucket></b-icon-bucket> {{ $t('buy_diamond') }}</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="text-center diamond-detail-footer">
        <b-row>
          <b-col cols="12" md="3" sm="6" class="no-padding" v-if="diamond.item.ap !== undefined && diamond.item.ap !== null && diamond.item.ap !== ''">
            <a :href="diamond.item.ap" target="_blank" class="btn btn-primary btn-block"><b-icon-camera-video></b-icon-camera-video> {{ $t('video') }}</a>
          </b-col>
          <b-col cols="12" :md="diamond.item.ap !== undefined && diamond.item.ap !== '' && diamond.item.ap !== null ? 3 : 4" :sm="diamond.item.ap !== undefined && diamond.item.ap !== null && diamond.item.ap !== '' ? 6 : 12" class="no-padding">
            <a :href="(diamond.item.cp !== null) ? diamond.item.cp : diamond.item.or" v-if="diamond.item.cp !== null || diamond.item.or !== null" target="_blank" class="btn btn-primary btn-block"><b-icon-file-text></b-icon-file-text> {{ diamond.item.lab }} {{ diamond.item.cn }}</a>
          </b-col>
          <b-col cols="12" :md="diamond.item.ap !== undefined && diamond.item.ap !== '' && diamond.item.ap !== null ? 3 : 4" sm="6" class="no-padding">
            <a :href="'tel:' + contactDetails.phone" class="btn btn-primary btn-block"><b-icon-phone></b-icon-phone> {{ contactDetails.phone }}</a>
          </b-col>
          <b-col cols="12" :md="diamond.item.ap !== undefined && diamond.item.ap !== '' && diamond.item.ap !== null ? 3 : 4" sm="6" class="no-padding">
            <a :href="'mailto:' + contactDetails.email + '?subject=Diamond information request | ' + diamond.item.lab + ' ' + diamond.item.cn" class="btn btn-primary btn-block"><b-icon-envelope></b-icon-envelope> {{ $t('email_us') }}</a>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'diamond-detail',
  props: {
    diamond: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'currencyCodes',
      'priceSettings',
      'contactDetails',
      'pluginToken',
      'pluginSettings',
      'postResult'
    ])
  },
  data () {
    return {
      showImages: true,
      byDiamondClicked: false,
      imageStatus: 'loading'
    }
  },
  methods: {
    ...mapActions([
      'post',
      'postLog'
    ]),
    checkImage (url) {
      const img = new Image()

      img.onload = () => {
        this.imageStatus = 'loaded'
      }

      img.onerror = () => {
        this.imageStatus = 'error'
      }

      img.src = url
    },
    buyDiamond (diamond) {
      this.byDiamondClicked = true
      diamond.pluginId = this.pluginSettings.id
      diamond.price = this.$options.filters.calculatePrice(diamond.tp, this.priceSettings.rate, diamond.ct, this.priceSettings.margins).toFixed(0)
      this.post(diamond).then(() => {
        if (this.postResult.redirect !== undefined && this.postResult.redirect !== '') {
          window.location.href = this.postResult.redirect
        }
      }).catch((error) => {
        this.handleError(error)
      })
    }
  },
  handleError (error) {
    // eslint-disable-next-line
    console.error('An error occurred: ' + error)
  },
  mounted () {
    this.postLog({
      pluginId: this.pluginSettings.id,
      action: 'action.diamondclick',
      payload: this.diamond.item
    })
    this.checkImage(this.diamond.item.ip)
  },
  created () {
    const shapes = ['Round', 'Princess', 'Pear', 'Heart', 'Radiant', 'Marquise', 'Triangle', 'Trilliant', 'Oval', 'Asscher', 'Emerald', 'Baguette', 'Cushion', 'Half Moon', 'Shield', 'Taper Baguette', 'Kite']

    this.showImages = shapes.indexOf(this.diamond.item.cut) > -1
  }
}
</script>

<style scoped>
.diamond-title {
  background:var(--selected-background-color);
  color:var(--unselected-background-color);
  padding:15px 0px;
  margin-bottom:15px;
}

.spinner-grow {
  color:var(--button-color);
}

.btn {
  height:38px;
  font-size:14px;
  padding-top:10px;
}

.diamond-image, .cert-holder {
  border-radius:0.25em;
}

.cert-holder {
  background:#FFFFFF;
}

.no-padding {
  padding:0px;
}

.diamond-title h2:first-child {
    margin-left:10px;
  }

  .diamond-detail-footer {
    background-color:var(--row-hover-background-color);
  }
@media (min-width: 320px) {
  .diamond-title h2 {
    font-size:14px;
  }

  .diamond-image-holder {
    background:#FFFFFF;
    border-radius:0.25em;
  }

  .diamond-image-holder img, .diamond-image-holder svg {
    width:100%;
    max-width:350px;
  }

  .diamond-detail-footer .btn {
    height:50px;
  }
}
@media (min-width: 576px) {
  .diamond-title h2 {
    font-size:18px;
  }

  .diamond-detail-footer .btn {
    height:38px;
  }
}
@media (min-width: 768px) {
  .diamond-title h2 {
    font-size:26px;
  }
}
</style>
