<template>
  <b-col class="table-background mt-5" cols="12">
    <b-row class="mb-5">
      <b-col cols="12">
        <b-table
          show-empty
          striped
          hover
          :items="diamonds"
          :fields="fields"
          :busy.sync="isBusy"
          :sort-by="rawSort.field"
          :sort-desc="rawSort.type!=='asc'"
          :current-page="currentPage"
          :per-page="0"
          ref="table"
          selectable
          select-mode="single"
          @sort-changed="sortDiamonds"
          @row-clicked="navigateToDetail"
          id="tableSearchResult"
        >
          <template v-slot:table-busy>
            <b-row>
              <b-col cols="12">
                <h4 class="text-center">
                  <b-img :src="require('@/assets/img/round-loading.svg')"></b-img>
                </h4>
              </b-col>
            </b-row>
          </template>
          <template>
            <b-row>
              <b-col cols="12">
                <h6 class="text-center">
                  {{ $t('no_results_found') }}
                </h6>
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(cut)="row">
            {{ $t(row.item.cut.toLowerCase().replace(' ', '_')) }}
          </template>
          <template v-slot:cell(ct)="row">
            {{ row.item.ct | formatNumber(2, '.', '') }} Ct.
          </template>
          <template v-slot:cell(col)="row">
            {{ (row.item.col !== null) ? row.item.col : row.item.nfc }}
          </template>
          <template v-slot:cell(cl)="row">
            {{ row.item.cl }}
          </template>
          <template v-slot:cell(mk)="row">
            {{ (row.item.mk !== null) ? $t(row.item.mk.toLowerCase().replace(' ', '_')) : null }}
          </template>
          <template v-slot:cell(lab)="row">
            {{ (row.item.lab === null) ? 'NO CERT' : row.item.lab }}
          </template>
          <template v-slot:cell(ga)="row" v-if="pluginSettings.multipleDiamondTypes === true">
            {{ (row.item.ga === 'lab') ? $t('Lab Grown') : $t('Natural') }}
          </template>
          <template v-slot:cell(tp)="row">
            {{ row.item.tp | calculatePrice(priceSettings.rate, row.item.ct, priceSettings.margins) | formatNumber(0, ',', '.') }} <span v-html="currencyCodes[priceSettings.currency]"></span>
          </template>
          <template v-slot:row-details="data">
            <transition name="slide">
              <b-row v-if="showRowDetails">
                <b-col cols="12">
                  <diamond-detail :diamond="data"></diamond-detail>
                </b-col>
              </b-row>
            </transition>
          </template>
        </b-table>

        <b-row v-if="pageBusy && diamonds.length > 0">
          <b-col cols="12" class="text-center">
            <b-img :src="require('@/assets/img/round-loading.svg')"></b-img>
          </b-col>
        </b-row>
        <div ref="loadMoreTrigger" v-observe-visibility="visibilityChanged"></div>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import diamondDetail from './Detail'

export default {
  name: 'diamond-results',
  components: {
    diamondDetail
  },
  data () {
    return {
      showRowDetails: false,
      perPage: 100,
      tableBreakPoints: {
        xs: 375,
        sm: 480,
        md: 768,
        lg: 960
      }
    }
  },
  methods: {
    ...mapActions([
      'setSort',
      'setPage'
    ]),
    sortDiamonds (sortParams) {
      this.setSort(sortParams)
    },
    pageDiamonds (page) {
      this.setPage(page)
    },
    visibilityChanged (isVisible, entry) {
      if (isVisible === true && this.loadMore === true) {
        this.setPage(1)
      }
    },
    detailsClassFn (item, rowType) {
      if (rowType === 'row-details') {
        return 'diamond-detail'
      }
    },
    navigateToDetail (row) {
      if (row._showDetails === true) {
        row._showDetails = false
        this.showRowDetails = false
      } else {
        for (let i = 0; i < this.diamonds.length; i++) {
          this.diamonds[i]._showDetails = false
          this.showRowDetails = false
        }

        row._showDetails = true
        setTimeout(() => {
          this.showRowDetails = true
        }, 20)
      }
    }
  },
  computed: {
    ...mapGetters([
      'priceSettings',
      'currencyCodes',
      'pluginSettings'
    ]),
    fields () {
      const baseFields = [
        { key: 'cut', label: this.$i18n.t('shape'), sortable: true },
        { key: 'ct', label: this.$i18n.t('carat'), sortable: true },
        { key: 'col', label: this.$i18n.t('color'), sortable: true, thClass: 'd-none d-sm-table-cell', tdClass: 'd-none d-sm-table-cell' },
        { key: 'cl', label: this.$i18n.t('clarity'), sortable: true, thClass: 'd-none d-sm-table-cell', tdClass: 'd-none d-sm-table-cell' },
        { key: 'mk', label: this.$i18n.t('cut'), sortable: true, thClass: 'd-none d-md-table-cell', tdClass: 'd-none d-md-table-cell' },
        { key: 'lab', label: this.$i18n.t('certificate'), sortable: true, thClass: 'd-none d-lg-table-cell', tdClass: 'd-none d-lg-table-cell' }
      ]

      if (this.pluginSettings && this.pluginSettings.multipleDiamondTypes === true) {
        baseFields.push({ key: 'ga', label: this.$i18n.t('type'), sortable: true })
      }

      baseFields.push({ key: 'tp', label: this.$i18n.t('price'), sortable: true })
      return baseFields
    },
    diamonds: {
      get: function () {
        return this.$store.getters.diamonds
      },
      set: function () {
      }
    },
    isBusy: {
      get: function () {
        return this.$store.getters.isBusy
      },
      set: function () {
      }
    },
    totalRecords: {
      get: function () {
        return this.$store.getters.totalRecords
      },
      set: function () {
      }
    },
    sortBy: {
      get: function () {
        return this.$store.getters.sortBy
      },
      set: function () {
      }
    },
    loadMore: {
      get: function () {
        return this.$store.getters.loadMore
      },
      set: function () {
      }
    },
    pageBusy: {
      get: function () {
        return this.$store.getters.pageBusy
      },
      set: function () {
      }
    },
    rawSort: {
      get: function () {
        return this.$store.getters.rawSort
      },
      set: function () {
      }
    },
    currentPage: {
      get: function () {
        return this.$store.getters.currentPage
      },
      set: function (value) {
        this.$store.commit('UPDATE_CURRENT_PAGE', value)
      }
    }
  }
}
</script>
<style scoped>
  .slide-enter-active {
    animation: slide-in 0.5s ease-out forwards;
  }

  .slide-leave {

  }

  .slide-leave-active {
    animation: slide-out 0.5s ease-out forwards;
  }

  .bottom-spacer {
    height:100px;
  }

  @keyframes slide-in {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  @keyframes slide-out {
    from {
      transform: translateY(0px);
      opacity: 1;
    }
    to {
      transform: translateY(20px);
      opacity:0;
    }
  }
</style>
