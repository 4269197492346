import axiosAuth from '../../axios/axios-plugin-api-auth'
import axios from 'axios'

const state = {
  token: null,
  result: null,
  languageMessages: {}
}

const mutations = {
  'UPDATE_TOKEN' (state, value) {
    state.token = value
  },
  'UPDATE_RESULT' (state, value) {
    state.result = value
  },
  'UPDATE_LANGUAGE_MESSAGES' (state, value) {
    const languageMessages = {}

    if (value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        const newValue = {
          [value[i].constant]: value[i].value
        }
        Object.assign(languageMessages, newValue)
      }
    }
    state.languageMessages = languageMessages
  }
}

const actions = {
  getToken ({ commit, dispatch, state }, body) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const currentDate = new Date(Date.now())
      let dateCheck = false

      if (state.token !== null) {
        const tokenDate = new Date(state.token.expiryDate)
        dateCheck = tokenDate > currentDate
      }

      if (state.token === null || !dateCheck) {
        await axiosAuth.post('oauth', {
          grant_type: 'client_credentials'
        }).then((data) => {
          const d = new Date(Date.now())
          d.setSeconds(d.getSeconds() + data.data.expires_in)
          data.data.expiryDate = d
          commit('UPDATE_TOKEN', data.data)
        }).catch((error) => {
          resolve(error)
        })
      }

      resolve()
    })
  },
  post ({ commit, dispatch, state }, body) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await dispatch('getToken')
      const userInformation = JSON.parse(localStorage.userInformation)

      await axios.create({
        baseURL: process.env.VUE_APP_PLUGINS_API_URL,
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + state.token.access_token
        }
      })
        .post('cart/' + body.pluginId, {
          diamond_id: body.cn,
          ip: userInformation.ip,
          city: userInformation.city,
          region: userInformation.region,
          country: userInformation.country,
          loc: userInformation.loc,
          postal_code: userInformation.postal,
          timezone: userInformation.timezone,
          payload: body
        })
        .then(res => {
          commit('UPDATE_RESULT', res.data)
          resolve()
        })
        .catch(() => {
          resolve()
        })

      resolve()
    })
  },
  postLog ({ commit, dispatch, state }, body) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await dispatch('getToken')
      const userInformation = JSON.parse(localStorage.userInformation)

      const payload = {
        action: body.action,
        ip: userInformation.ip,
        city: userInformation.city,
        region: userInformation.region,
        country: userInformation.country,
        loc: userInformation.loc,
        postal_code: userInformation.postal,
        timezone: userInformation.timezone
      }

      if (body.payload !== undefined) {
        payload.payload = body.payload
      }

      axios.create({
        baseURL: process.env.VUE_APP_PLUGINS_API_URL,
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + state.token.access_token
        }
      })
        .post('log/' + body.pluginId, payload)
        .then(res => {
          resolve()
        })
        .catch(() => {
          resolve()
        })

      resolve()
    })
  },
  getLanguageMessages ({ commit, dispatch }, body) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await dispatch('getToken')
      await axios.create({
        baseURL: process.env.VUE_APP_PLUGINS_API_URL,
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + state.token.access_token
        }
      }).get('i18n/' + body.lang)
        .then(res => {
          commit('UPDATE_LANGUAGE_MESSAGES', res.data)
          resolve()
        })
        .catch(() => {
          resolve()
        })
    })
  }
}

const getters = {
  pluginToken (state) {
    return state.token
  },
  postResult (state) {
    return state.result
  },
  languageMessages (state) {
    return state.languageMessages
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
